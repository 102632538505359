import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles, TableCell, Tooltip } from "@material-ui/core";
import React, { FC } from "react";
import { Control, useWatch } from "react-hook-form";
import { getChargeTitleTooltip } from "../services";
import { AccountingCharge } from "../types";

const useStyles = makeStyles(theme => ({
  tableChargeRowAmountOverrideWarning: {
    padding: `${theme.spacing(1)}px`,
    textAlign: "center"
  },
  tooltip: {
    fontSize: "1rem",
    whiteSpace: "pre-line",
    textAlign: "center"
  }
}));

type Props = {
  charge: AccountingCharge,
  control: Control<{
    charges: AccountingCharge[];
  }, any>,
  index: number
}

export const WarningAmountTooltip: FC<Props> = ({ charge, control, index }) => {
  const classes = useStyles();
  const {
    tax,
    buyer_at,
    buyer_before,
    seller_at,
    seller_before,
    by_others
  } = useWatch({
    control,
    name: `charges.${index}`
  });

  const amount = tax + buyer_at + buyer_before + seller_at + seller_before + by_others;

  return (
    <TableCell className={classes.tableChargeRowAmountOverrideWarning}>
      {(charge.kind !== "manual" && amount !== charge.amount_calculated) && (
        <Tooltip
          title={getChargeTitleTooltip(charge)}
          arrow
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <FontAwesomeIcon icon="exclamation-circle" size="2x" color="rgb(245 196 3)" />
        </Tooltip>
      )}
    </TableCell>
  );
};