import React, { FC } from "react";
import { Controller, useFieldArray, UseFormReturn } from "react-hook-form";
import { ProjectedPaymentsForm } from "../services";
import TableComponent from 'components/Table';
import InputCell from "./input-cell";
import { Box, IconButton, makeStyles } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextButton } from "components/TextButton";
import NumberField from "components/number-field";

const useStyles = makeStyles((theme: any) => ({
  centerText: {
    textAlign: "center",
    '&>div>input': {
      textAlign: "center",
    }
  },
}));

type Props = {
  methods: UseFormReturn<ProjectedPaymentsForm, any, undefined>,
}

export const YearsRangesTable: FC<Props> = ({ methods }) => {
  const {
    fields: yearRangesFields,
    append,
    remove
  } = useFieldArray({
    control: methods.control,
    name: "year_ranges",
  });
  const watch = methods.watch();

  const classes = useStyles();

  return (
    <Box justifyItems="flex-end">
      <TextButton
        text="Add"
        onClick={() => append({
          range: [0, 0],
          entryId: "",
          amount: 0
        })}
        textColor="#FB8C00"
        icon="plus"
        disabled={yearRangesFields.length >= 4}
      />
      <TableComponent
        disabledBorderLeft
        cellAlignment="left"
        columns={{
          actions: {
            header: { title: 'Actions', align: 'center', width: '80px' },
            renderer: ({ indexRow = 0 }) => (
              <Box display="flex" justifyContent="center">
                <IconButton
                  style={{ padding: 0 }}
                  onClick={() => {
                    remove(indexRow);
                  }}
                >
                  <FontAwesomeIcon icon="trash" size="xs" />
                </IconButton>
              </Box>
            ),
          },
          yearsRange: {
            header: { title: 'Range', align: 'left' },
            renderer: ({ indexRow = 0, row }) => {
              const yearRanges = watch.year_ranges;
              return (
                <Box display="flex" style={{ gap: "10px", justifyContent: "flex-start", alignItems: "center" }} key={row.id}>
                  <Box width="50px" style={{ textAlign: "center" }}>
                    <Controller
                      control={methods.control}
                      name={`year_ranges.${indexRow}.range.0`}
                      render={({ field }) => (
                        <InputCell
                          value={field.value || 0}
                          onChangeInput={(e: any) => {
                            const value = Number(e.target.value);
                            field.onChange(value > yearRanges[indexRow][1] ? 0 : value);
                          }}
                          inputClass={classes.centerText}
                        />
                      )}
                    />
                  </Box>
                  <span>-</span>
                  <Box width="50px">
                    <Controller
                      control={methods.control}
                      name={`year_ranges.${indexRow}.range.1`}
                      render={({ field }) => (
                        <InputCell
                          value={field.value || 0}
                          onChangeInput={(e: any) => {
                            const value = Number(e.target.value);
                            field.onChange(value < yearRanges[indexRow][0] ? 0 : value);
                          }}
                          inputClass={classes.centerText}
                        />
                      )}
                    />
                  </Box>
                </Box>);
            },
          },
          amount: {
            header: { title: 'Mortgage Insurance', align: 'left' },
            renderer: ({ indexRow = 0, row }) => {
              return (
                <Box display="flex" style={{ gap: "10px", justifyContent: "flex-start", alignItems: "center" }} key={row.id}>
                  <Controller
                    control={methods.control}
                    name={`year_ranges.${indexRow}.amount`}
                    render={({ field }) => (
                      <NumberField
                        color="secondary"
                        label="Amount"
                        name="amount"
                        handleBlur={field.onBlur}
                        value={field.value}
                        onValueChange={({ floatValue }) => field.onChange(floatValue)}
                        prefix="$"
                        thousandSeparator
                        disabled={methods.getValues(`year_ranges.${indexRow}.entryId`) === ""}
                      />
                    )}
                  />
                </Box>);
            },
          }
        }}
        dataSet={yearRangesFields}
      />
    </Box>
  );
};