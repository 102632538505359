import { useState } from 'react';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { AccountingType } from 'v2-types/order';

import { getOrder } from './querys';
import { updateOrderPolicy } from './mutations';
import useAlert from 'utils/alert';
import { useAccountingUpdateSocketEvent } from 'hooks/useAccountingUpdateFromSocket';

const useGetCharges = (currentLetter: string) => {
  const [accounting, setAccounting] = useState<AccountingType[]>([]);
  const { id: orderId } = useParams<{ id: string }>();
  const [hideTax, setHideTax] = useState(true);
  const history = useHistory();
  const [discloSection, setDiscloSection] = useState('');
  const showAlert = useAlert();
  const [showNewInfoAlert, setShowNewInfoAlert] = useState<boolean>(false);
  const queryCache = useQueryCache();

  useAccountingUpdateSocketEvent({
    callback: () => setShowNewInfoAlert(true)
  });

  const [setChargesSection, responseOrderPolicy] = useMutation(updateOrderPolicy, {
    onSuccess: (data) => {
      showAlert('Charges Updated', 'success');
      setDiscloSection(data.updateOrderPolicy.lenders_policy.disclosure_section || '');
      setAccounting(
        data.updateOrderPolicy.accounting?.filter((charge) => charge.number !== null) || []
      );
    },
  });

  const redirectToPolicyInfo = () => {
    history.push(`/orders/details/${orderId}/policy-info-and-rates`);
  };

  const filterCharges = (charges: AccountingType[]) => {
    const isLetterE = currentLetter === 'E';
    if (isLetterE) {
      return charges.filter((charge) => charge.letter === currentLetter);
    }

    return charges.filter((charge) => charge.letter === currentLetter && charge.number !== null);
  };

  const { isLoading, isFetching } = useQuery(
    ['accounting-order', orderId],
    () =>
      getOrder(orderId, {
        accounting: currentLetter,
      }),
    {
      enabled: !!orderId && !!currentLetter,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data) {
          setAccounting(filterCharges(data.getOrder.accounting));
          setDiscloSection(data.getOrder.lenders_policy.disclosure_section);
        }
        setShowNewInfoAlert(false);
      },
    }
  );

  const updateAccounting = (data: AccountingType[]) => {
    setAccounting(filterCharges(data));
  };

  const reloadAmounts = () => queryCache.invalidateQueries(['accounting-order', orderId]);

  return {
    accounting: accounting.sort((chargeA, chargeB) => chargeA.number - chargeB.number),
    isLoading,
    isFetching,
    updateAccounting,
    hideTax,
    setHideTax,
    redirectToPolicyInfo,
    discloSection,
    setChargesSection,
    policyIsLoading: responseOrderPolicy.isLoading,
    showNewInfoAlert,
    reloadAmounts,
    filterCharges
  };
};

export default useGetCharges;
