import {
  requestPartyDocuments,
  RequestPartyDocumentsParams,
} from 'graphql/documents/mutations';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import useAlert from 'utils/alert';
import { DocumentPartyRequest, DocumentsType } from 'v2-types/order';

import { AssociationType } from '../../types';
import { getInitialData } from './services';
import { RequestPartyDocuments } from './types';

type UseRequestDocumentsParams = {
  parties: AssociationType[];
  documents: DocumentsType[];
  onClose: () => void;
};

export const useRequestDocuments = ({
  parties,
  documents,
  onClose,
}: UseRequestDocumentsParams) => {
  const { t } = useTranslation();
  const Alert = useAlert();
  const { id: orderId } = useParams<{ id: string }>();
  const [errors, setErrors] = useState<null | string>(null);
  const {
    handleSubmit,
    control,
    getValues,
    reset: resetForm,
    formState: { isValid: isFormValid },
  } = useForm<RequestPartyDocuments>({
    defaultValues: getInitialData({
      orderId,
      parties,
      documents,
    }),
    mode: 'onChange',
  });

  useEffect(() => {
    resetForm(
      getInitialData({
        orderId,
        parties,
        documents,
      })
    );
  }, [parties, documents]);

  const [requestDocuments, { isLoading: isRequestDocumentsLoading }] = useMutation(requestPartyDocuments, {
    onSuccess: () => {
      setErrors(null);
      Alert(t('documents:document-request-sended'), 'success');
      resetForm(
        getInitialData({
          orderId,
          parties,
          documents,
        })
      );
      onClose();
    },
    onError: () => {
      setErrors(t('documents:lack-of-email'));
      Alert(t('common:unexpected-error'), 'error');
    },
  });

  const onSendRequest = handleSubmit(async (requestInfo) => {
    const requests: DocumentPartyRequest[] = [];
    requestInfo.requests.forEach((request) => {
      request.files
        .filter((file) => file.checked)
        .forEach((file) => {
          requests.push({
            party_id: request.partyId,
            file_id: file.archiveId,
          });
        });
    });

    const requestDocument: RequestPartyDocumentsParams = {
      id: requestInfo.id,
      notBefore: new Date(requestInfo.notBefore).toISOString(),
      expiresIn: new Date(requestInfo.expiresIn).toISOString(),
      requests,
    };
    requestDocuments(requestDocument);
  });

  return {
    t,
    isRequestDocumentsLoading,
    isFormValid,
    control,
    getValues,
    onSendRequest,
    errors,
    setErrors
  };
};
