import { makeStyles, TableCell, TableRow } from '@material-ui/core';
import Actionbuttons from 'components/common/actions-buttons';
import React, { FC } from 'react';
import { IntlCurrencyFormat } from 'utils/helpers';

import { CellIndicator, CellText } from '../../components/table-cells';
import { formattedCharge } from '../services';

type hudRowProps = {
  row: formattedCharge;
  handleEdit: (
    param: Pick<formattedCharge, 'id' | 'chargeData' | 'description' | 'number'>
  ) => void;
  handleDelete: (
    param: Pick<formattedCharge, 'id' | 'chargeData' | 'description' | 'number'>
  ) => void;
  handleSelectItem: (
    param: Pick<formattedCharge, 'id' | 'chargeData' | 'description' | 'number'>
  ) => void;
  handleClick: (event: React.MouseEvent, number: number) => void;
};

const useStyles = makeStyles((theme: any) => ({
  borderCellStyle: {
    borderColor: theme.palette.input.border,
    borderWidth: 1,
    borderStyle: 'solid',
    opacity: 1,
    paddingTop: theme.spacing(1.2),
    paddingBottom: theme.spacing(1.2),
  },
}));

const HudRow: FC<hudRowProps> = ({
  row,
  handleDelete,
  handleEdit,
  handleSelectItem,
  handleClick,
}) => {
  const classes = useStyles();
  return (
    <TableRow>
      <TableCell className={classes.borderCellStyle}>
        <Actionbuttons
          handleEdit={(e) => {
            e.stopPropagation();
            handleEdit(row);
          }}
          handleDelete={row.chargeData.kind === 'manual' ? (e) => {
            e.stopPropagation();
            handleDelete(row);
          } : undefined}
        />
      </TableCell>
      <TableCell
        className={classes.borderCellStyle}
        onClick={(e) => {
          e.stopPropagation();
          handleClick(e, row.number);
          handleSelectItem(row);
        }}
        style={{ cursor: 'pointer' }}
      >
        <CellIndicator isActive={false} position={row.number} />
      </TableCell>
      <TableCell className={classes.borderCellStyle}>
        <CellText
          position="left"
          backgroundColor="transparent"
          colorText="#4F5B62"
          data={row.description}
        />
      </TableCell>
      <TableCell className={classes.borderCellStyle} style={{ width: '8rem' }}>
        <CellText
          position="left"
          backgroundColor="transparent"
          colorText="#4F5B62"
          data={IntlCurrencyFormat(row.chargeData.amount || 0).toString()}
        />
      </TableCell>
      <TableCell className={classes.borderCellStyle} style={{ width: '8rem' }}>
        <CellText
          position="left"
          backgroundColor="transparent"
          colorText="#4F5B62"
          data={row.chargeData.months?.toString() || '0'}
        />
      </TableCell>
      <TableCell
        className={classes.borderCellStyle}
        align="right"
        style={{ width: '8rem' }}
      >
        <CellText
          position="left"
          backgroundColor="transparent"
          colorText="#4F5B62"
          data={IntlCurrencyFormat(row.borrowerFunds || 0).toString()}
        />
      </TableCell>
      <TableCell
        className={classes.borderCellStyle}
        align="right"
        style={{ width: '8rem' }}
      >
        <CellText
          position="left"
          backgroundColor="transparent"
          colorText="#4F5B62"
          data={IntlCurrencyFormat(row.sellerFunds || 0).toString()}
        />
      </TableCell>
    </TableRow>
  );
};

export default HudRow;
