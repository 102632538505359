import { Box, Paper, Tabs, Tab, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import Disbursments from '../new-disbursments/disbursments';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: any) => ({
  container: { padding: theme.spacing(3) },
}));

const AccountingLedger = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box className={classes.container}>
      <Paper>
        <Tabs value={value} onChange={handleChange}>
          <Tab label={t('disbursments:ledger')} />
          <Tab label={t('disbursments:disbursements')} />
          <Tab label={t('disbursments:receipts')} />
          <Tab label={t('disbursments:netfunded')} />
        </Tabs>
        {value === 0 && <Disbursments kindEntry={['disbursement', 'receipt', 'netfunded']} titleTab="ledger" />}
        {value === 1 && <Disbursments kindEntry={['disbursement']} titleTab="disbursements" />}
        {value === 2 && <Disbursments kindEntry={['receipt']} titleTab="receipts" />}
        {value === 3 && <Disbursments kindEntry={['netfunded']} titleTab="netfunded" />}
      </Paper>
    </Box>
  );
};

export default AccountingLedger;
