import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React, { FC } from 'react';
import { AccountingType } from 'v2-types/order';
import { formatMoney } from 'utils/helpers';
import { Controller, UseFormReturn } from 'react-hook-form';
import NumberField from 'components/number-field';
import { createEntryForm } from '../../types';
import { TFunction } from 'i18next';

type TableChargesProps = {
  accounting: AccountingType[];
  methods: UseFormReturn<createEntryForm, any, undefined>;
  selectedPayee: string;
  t: TFunction;
};

const useStyles = makeStyles((theme: any) => ({
  containerTable: {
    maxHeight: '600px',
    overflow: 'scroll',
  },
}));

const TableCharges: FC<TableChargesProps> = ({ accounting, methods, selectedPayee, t }) => {
  const {
    setValue,
    control,
    watch,
    trigger,
    formState: { errors },
    setError,
    clearErrors,
  } = methods;
  const updatedTargets = [...watch('targets')];

  const classes = useStyles();

  return (
    <TableContainer
      component={Paper}
      style={{ maxHeight: 450, overflowY: 'auto', margin: '20px 0' }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <></>
            </TableCell>
            <TableCell>
              <Typography>{t('disbursments:description')}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{t('disbursments:total')}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{t('disbursments:already-accounted')}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{t('disbursments:amount')}</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.containerTable}>
          {accounting
            .filter((entry) =>
              entry.involved_parties.some(
                (party) => selectedPayee === '' || party.name.trim() === selectedPayee
              )
            )
            .map((entry, index) => (
              <TableRow key={entry._id}>
                <TableCell>
                  <Controller
                    control={control}
                    name={`targets.${index}.checked`}
                    render={({ field: { ...field } }) => (
                      <Checkbox
                        checked={field.value || false}
                        {...field}
                        onChange={(e) => {
                          const {
                            target: { checked },
                          } = e;
                          if (checked && !updatedTargets[index]?.amount) {
                            const amount =
                              entry.involved_parties[0].amount -
                              entry.involved_parties[0].accounted_amount;
                            setValue(`targets.${index}.amount`, amount);
                            setValue(`targets.${index}.target_entry_id`, entry._id);
                            setValue(
                              `targets.${index}.target_association_id`,
                              entry.involved_parties[0]._id
                            );
                            setValue(
                              `targets.${index}.target_association_kind`,
                              entry.involved_parties[0].kind
                            );
                          }
                          setValue(`targets.${index}.checked`, checked);
                        }}
                      />
                    )}
                  />
                </TableCell>
                <TableCell>
                  <Typography>{entry.description}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{formatMoney(entry.involved_parties[0].amount)}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{formatMoney(entry.involved_parties[0].accounted_amount)}</Typography>
                </TableCell>
                <TableCell>
                  <Controller
                    control={control}
                    name={`targets.${index}.amount`}
                    render={({ field: { ref, ...field } }) => (
                      <NumberField
                        {...field}
                        disabled={!updatedTargets[index]?.checked}
                        value={
                          field.value
                            ? field.value
                            : entry.involved_parties[0].amount -
                              entry.involved_parties[0].accounted_amount
                        }
                        ref={ref}
                        thousandSeparator=","
                        prefix="$"
                        label={t('disbursments:amount')}
                        onChange={undefined}
                        onValueChange={(e) => {
                          const amount = e.floatValue || 0;

                          const maxAmount =
                            Math.round(
                              (entry.involved_parties[0].amount -
                                entry.involved_parties[0].accounted_amount) *
                                100
                            ) / 100;

                          if (amount > maxAmount) {
                            setError(`targets.${index}.amount`, {
                              type: 'manual',
                              message: `${t('disbursments:error-max-amount')} ${formatMoney(
                                maxAmount
                              )}`,
                            });
                          } else if (errors.targets?.[index]?.amount) {
                            clearErrors(`targets.${index}.amount`);
                          } else {
                            trigger('targets');
                          }

                          setValue(`targets.${index}.amount`, amount);
                          setValue(`targets.${index}.target_entry_id`, entry._id);
                          setValue(
                            `targets.${index}.target_association_id`,
                            entry.involved_parties[0]._id
                          );
                          setValue(
                            `targets.${index}.target_association_kind`,
                            entry.involved_parties[0].kind
                          );
                        }}
                        error={!!errors.targets?.[index]?.amount}
                        helperText={
                          errors.targets?.[index]?.amount?.message
                            ? t(`disbursments:${errors.targets?.[index]?.amount?.message}`)
                            : ''
                        }
                      />
                    )}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableCharges;
