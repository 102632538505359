import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React, { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { createEntryForm } from '../types';
import SelectField from 'components/select-field';
import { AccountingType } from 'v2-types/order';
import { TFunction } from 'i18next';
import TableCharges from './tableChargesComponents/tableCharges';
import TextField from 'components/text-field';
import DatePicker from 'components/date-picker/date-picker';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaModalAddEntry } from '../services';

type ModalAddEntryType = {
  accounting: AccountingType[];
  handleClose: () => void;
  t: TFunction;
  kindEntry: 'disbursement' | 'receipt' | 'netfunded';
  handleCreationEntry: (data: any) => void;
};

const allowedAssociations = [
  {
    code: 'cash',
    description: 'Cash',
  },
  {
    code: 'check',
    description: 'Check',
  },
  {
    code: 'wire',
    description: 'Wire Transfer',
  },
  {
    code: 'netfunded',
    description: 'Net Funded',
  },
];

const useStyles = makeStyles((theme: any) => ({
  containerEmpty: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `${theme.spacing(3)}px 0`,
  },
}));

export const ModalAddEntry: FC<ModalAddEntryType> = ({
  handleClose,
  accounting,
  t,
  kindEntry,
  handleCreationEntry,
}) => {
  const classes = useStyles();
  const [selectedPayee, setSelectedPayee] = useState('');

  const defaultCode = kindEntry === 'netfunded' ? 'netfunded' : 'cash';

  const methods = useForm<createEntryForm>({
    resolver: yupResolver(schemaModalAddEntry),
    defaultValues: {
      code: defaultCode,
      targets: [],
      description: '',
      entryDate: '',
      kind: kindEntry,
    },
    mode: 'onChange',
  });
  const {
    handleSubmit,
    control,
    formState: { isValid, errors },
    trigger,
  } = methods;

  const handleSubmitForm = handleSubmit((data) => {
    handleCreationEntry(data);
    handleClose();
  });

  const listPayees = [
    ...new Set(
      accounting.flatMap((entry) =>
        entry.involved_parties
          .filter((party) => party.kind === 'Payee')
          .map((party) => party.name.trim())
      )
    ),
  ];

  return (
    <Dialog open fullWidth maxWidth="md">
      <DialogTitle>{t('disbursments:createLinkEntry')}</DialogTitle>
      <DialogContent>
        <SelectField
          value={selectedPayee}
          data={listPayees}
          dataKey=""
          dataValue=""
          dataText=""
          label={t('disbursments:payee')}
          handleChange={(item) => setSelectedPayee(item.target.value)}
        />
        <form onSubmit={handleSubmitForm}>
          {accounting.length > 0 ? (
            <TableCharges
              accounting={accounting}
              methods={methods}
              selectedPayee={selectedPayee}
              t={t}
            />
          ) : (
            <Box className={classes.containerEmpty}>
              <Typography variant="h6">{t('disbursments:empty-entry')}</Typography>
            </Box>
          )}
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="description"
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    value={field.value}
                    inputRef={ref}
                    label={t('disbursments:description')}
                    handleBlur={() => {
                      trigger('description');
                    }}
                    error={!!errors.description}
                    helperText={
                      errors.description?.message
                        ? t(`disbursments:${errors.description.message}`)
                        : ''
                    }
                  />
                )}
              />
            </Grid>
            {kindEntry !== 'netfunded' && (
              <Grid item xs={3}>
                <Controller
                  control={control}
                  name="code"
                  render={({ field: { ref, ...field } }) => (
                    <SelectField
                      {...field}
                      value={field.value || 'cash'}
                      inputRef={ref}
                      data={allowedAssociations}
                      dataKey="code"
                      dataValue="code"
                      dataText="description"
                      label={t('users:kind')}
                      handleBlur={() => {
                        trigger('code');
                      }}
                      handleChange={field.onChange}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={kindEntry !== 'netfunded' ? 3 : 6}>
              <Controller
                name="entryDate"
                control={control}
                render={({ field: { ref, value, ...field } }) => (
                  <DatePicker
                    {...field}
                    inputRef={ref}
                    disableToolbar={false}
                    label={t('accounting:entry-date')}
                    value={value as string | Date}
                    handleBlur={() => {
                      trigger('entryDate');
                    }}
                    error={!!errors.entryDate}
                    helperText={
                      errors.entryDate?.message ? t(`disbursments:${errors.entryDate.message}`) : ''
                    }
                  />
                )}
              />
            </Grid>
          </Grid>

          <DialogActions style={{ margin: '20px 0 10px', padding: '0' }}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => handleClose()}
              disableElevation
            >
              <Typography variant="body2">{t('disbursments:close')}</Typography>
            </Button>
            <Button
              disabled={!isValid}
              color="secondary"
              variant="contained"
              type="submit"
              disableElevation
            >
              <Typography variant="body2">{t('disbursments:add')}</Typography>
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ModalAddEntry;
