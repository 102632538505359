/* eslint-disable radix */
import { Box, makeStyles, Typography } from '@material-ui/core';
import Actionbuttons from 'components/common/actions-buttons';
import TableComponent from 'components/Table';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Column, RendererParams } from 'types/common';
import { IntlCurrencyFormat } from 'utils/helpers';
import { AccountingInvolvedParty } from 'v2-types/order';

import useAccountingContext from '../../../services/useAccountingContext';
import InvolvedPartyAccounted from './involved-party-acocunted';
import SimpleCellText from './simple-text-cell';

export type ColumnsType = { [key: string]: Column };

const useStyles = makeStyles((theme: any) => ({
  drawLine: {
    paddingLeft: theme.spacing(7),
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: theme.spacing(2),
      bottom: '50%',
      width: theme.spacing(5),
      border: `1px solid ${theme.palette.border.line}`,
      borderTop: '0 none transparent',
      borderRight: '0 none transparent'
    },
  },
  voidlist: {
    fontWeight: 500,
    color: theme.palette.tab.offselected,
    marginLeft: theme.spacing(1.5)
  }

}));

const partiesColumns = (
  handleEdit: (row: any) => void,
  handleDelete: (row) => void,
  handledeleteLink: (paymentRecordId: string, associationKind: string, associationId: string) => void
): ColumnsType => ({
  actions: {
    header: {
      title: '',
      minWidth: '80px',
      width: '80px'
    },
    renderer: ({ row }: RendererParams) => (
      <Actionbuttons
        handleEdit={(e) => {
          e.stopPropagation();
          handleEdit(row);
        }}
        handleDelete={(e) => {
          e.stopPropagation();
          handleDelete(row);
        }}
      />
    ),
  },
  name: {
    header: {
      title: 'name',
      align: 'left'
    },
    renderer: ({ row }: RendererParams) => (
      <SimpleCellText textAlign="left">{row?.name}</SimpleCellText>
    ),
  },
  kind: {
    header: {
      title: 'kind',
      align: 'left'
    },
    renderer: ({ row }: RendererParams) => (
      <SimpleCellText textAlign="left">{row?.kind}</SimpleCellText>
    ),
  },
  amount: {
    header: {
      title: 'amount',
      align: 'left'
    },
    renderer: ({ row }: RendererParams) => (
      <SimpleCellText textAlign="right">{IntlCurrencyFormat(parseFloat(row.amount))}</SimpleCellText>
    ),
  },
  before_closing_amount: {
    header: {
      title: 'Before Closing',
      align: 'left'
    },
    renderer: ({ row }: RendererParams) => (
      <SimpleCellText textAlign="right">{IntlCurrencyFormat(parseFloat(row?.before_closing_amount)) || 0}</SimpleCellText>
    ),
  },
  at_closing_amount: {
    header: {
      title: 'At Closing',
      align: 'left'
    },
    renderer: ({ row }: RendererParams) => (
      <SimpleCellText textAlign="right">{IntlCurrencyFormat(parseFloat(row?.at_closing_amount)) || ''}</SimpleCellText>
    ),
  },
  payment_id: {
    header: {
      title: 'Payment ID',
      align: 'left',
    },
    renderer: ({ row }: RendererParams) => (
      <SimpleCellText textAlign="center">{row?.payment_reference || 'Not defined yet'}</SimpleCellText>
    ),
  },
  Accounting_by: {
    header: {
      title: 'Accounted By',
      align: 'left',
      minWidth: '220px',
      width: '220px'
    },
    renderer: ({ row }: RendererParams) => (
      <InvolvedPartyAccounted row={row} handleDeleteLink={handledeleteLink} />
    ),
  },
});

type involvedPartiesProps = {
  ledgerRow: any;
  handleEditLedgerAssociation: (row: any) => void,
  handleDeleteLedgerAssociation: (row: any) => void,
}

const InvolvedPartisTable: FC<involvedPartiesProps> = ({
  ledgerRow,
  handleEditLedgerAssociation,
  handleDeleteLedgerAssociation
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const parties: AccountingInvolvedParty[] = ledgerRow?.involved_parties || [];
  const { id } = useParams<{ id: string }>();
  const { handledeleteLink } = useAccountingContext()!;
  const handleEditPartyAssociation = (row: any) => {
    handleEditLedgerAssociation({ row, ledgerInfo: ledgerRow });
  };

  const handleDeletePartyAssociation = (row: any) => {
    handleDeleteLedgerAssociation({ row, ledgerInfo: ledgerRow });
  };

  const deleTeLinkMemoized = useCallback((paymentRecordId: string, associationKind: string, associationId: string) => {
    handledeleteLink(
      id,
      paymentRecordId,
      associationKind,
      associationId,
      ledgerRow.id
    );
  }, [handledeleteLink, id, ledgerRow.id]);
  return (
    <Box className={classes.drawLine}>
      {parties.length > 0
        ? (
          <TableComponent
            columns={partiesColumns(handleEditPartyAssociation, handleDeletePartyAssociation, deleTeLinkMemoized)}
            disabledBorderLeft
            dataSet={parties}
            cellAlignment="left"
          />
        )
        : (
          <Typography variant="body2" className={classes.voidlist}>
            {t('contact:no-items')}
          </Typography>
        )}
    </Box>
  );
};

export default InvolvedPartisTable;
