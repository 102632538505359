import React, { FC } from 'react';
import useDocumentFeesModal from './document-modal-calculation-hooks';
import { AccountingType } from 'v2-types/order';
import DocumentModalCalculationScene from './document-modal-calculation-scene';

type modalCalculationFeesProps = {
  open: boolean;
  onClose: () => void;
  handleSuccess: (accounting: AccountingType[]) => void;
  letter: string
}

const DocumentCalculationModal: FC<modalCalculationFeesProps> = ({ open, onClose, handleSuccess, letter }) => {
  const {
    methods,
    handleEntry,
    responseAddManualcharge,
    createErrors
  } = useDocumentFeesModal(onClose, handleSuccess, letter);

  return (
    <DocumentModalCalculationScene
      open={open}
      onClose={onClose}
      methods={methods}
      handleEntry={handleEntry}
      loading={responseAddManualcharge.isLoading}
      errors={createErrors}
    />
  );
};

export default DocumentCalculationModal;