import React, { FC, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { ProjectedPaymentsForm } from '../services';
import { TFunction } from 'react-i18next';
import InputCell from './input-cell';
import { formatCurrencyValueToNumber } from 'utils/helpers';
import { ClickAwayListener, makeStyles, Tooltip, Typography, withStyles, Box } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => ({
  text: {
    fontFamily: 'Roboto',
    color: theme.palette.primary.darker,
    fontWeight: 400,
    background: theme.palette.background.default,
    opacity: 1
  },
  textShadow: { textShadow: `0.25px 0.5px ${theme.palette.text.light}` },
  colorFontTooltip: { color: theme.palette.common.white },
}));

const LightTooltip = withStyles((theme: any) => ({
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

type TooltipInputPropos = {
  t: TFunction
  methods: UseFormReturn<ProjectedPaymentsForm, any, undefined>,
  name: string,
  onEditMortgage: () => void,
  calculatedValue?: number
}

const TooltipInput: FC<TooltipInputPropos> = ({ t, methods, name, onEditMortgage, calculatedValue }) => {
  const [open, setOpen] = useState(false);

  const classes = useStyles();
  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <LightTooltip
          PopperProps={{ disablePortal: true, }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          arrow
          placement="top"
          title={(
            <Typography component="span" className={classes.colorFontTooltip} variant="caption">
              {`The calculated value is: $${calculatedValue || 0}`}
            </Typography>
          )}
        >
          <Box
            style={{ width: '100%' }}
            onMouseEnter={handleTooltipOpen}
            onMouseLeave={handleTooltipClose}

          >
            <Controller
              control={methods.control}
              name={name as any}
              render={({ field }) => {
                return (
                  <InputCell
                    disabled
                    value={field.value}
                    prefix="$"
                    thousandSeparator
                    onChangeInput={(e: any) => {
                      const value = formatCurrencyValueToNumber(e.target.value);
                      field.onChange(value);
                    }}
                    onClickEdit={onEditMortgage}
                    helperText={calculatedValue !== field.value ? 'The calculated value was modified' : undefined}
                  />
                );
              }}
            />
          </Box>
        </LightTooltip>
      </div>
    </ClickAwayListener>
  );
};

export default TooltipInput;