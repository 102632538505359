import { TableCell, TableRow, Typography, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import moment from 'moment';
import DisbursmentsAccountedCell from './disbursmentsAccounted';
import { formatMoney } from 'utils/helpers';
import DisbursmentsBtns from './disbursmentsBtns';
import { AccountingType } from 'v2-types/order';
import { TFunction } from 'i18next';

const useStyles = makeStyles((theme: any) => ({
  date: {
    color: theme.palette.primary.darker,
    fontSize: 14,
    width: 'max-content',
  },
  unAccountedContainer: {
    display: 'flex',
    gap: 10,
    justifyContent: 'space-between',
  },
  text: {
    color: theme.palette.primary.darker,
    fontSize: 14,
    width: 'max-content',
    textTransform: 'capitalize',
  },
  amount: {
    textTransform: 'capitalize',
    color: theme.palette.error.main,
    fontSize: 14,
    fontWeight: 'bolder',
  },
}));

type DisbursmentRowProps = {
  disbursmentItem: AccountingType;
  handleDeleteEntry: (accountingId: string) => void;
  handleConfirmDelete: (
    openConfirm: boolean,
    callback: (() => void) | null,
    title: string,
    confirmationMessage: string
  ) => void;
  t: TFunction;
  kindEntry: string[];
  handleModals: (modalName: string, idEntry: string) => void;
};

const DisbursmentRow: FC<DisbursmentRowProps> = ({
  disbursmentItem,
  handleDeleteEntry,
  handleConfirmDelete,
  t,
  kindEntry,
  handleModals,
}) => {
  const classes = useStyles();

  const { accounted_for, amount, code, description, entry_date } = disbursmentItem;

  const sumAmountAccounted = accounted_for?.reduce((acc, num) => acc + num.amount, 0) ?? 0;

  const tableCells = [
    {
      id: 'description',
      content: <Typography className={classes.text}>{description}</Typography>,
      hide: false,
    },
    {
      id: 'amount',
      content: <Typography className={classes.text}>{formatMoney(amount)}</Typography>,
      hide: kindEntry.length > 1,
    },
    {
      id: 'method',
      content: <Typography className={classes.text}>{code}</Typography>,
      hide: false,
    },
    {
      id: 'date',
      content: (
        <Typography className={classes.date}>{moment(entry_date).format('MM-DD-YYYY')}</Typography>
      ),
      hide: false,
    },
    {
      id: 'accounted-for',
      content: <DisbursmentsAccountedCell accounted={accounted_for} t={t} />,
      hide: kindEntry.length > 1,
    },
    {
      id: 'options',
      content: (
        <DisbursmentsBtns
          handleDeleteEntry={() => handleDeleteEntry(disbursmentItem._id)}
          disbursmentItem={disbursmentItem}
          handleConfirmDelete={handleConfirmDelete}
          t={t}
          handleModals={handleModals}
        />
      ),
      hide: kindEntry.length > 1,
    },
    {
      id: 'debit',
      content: (
        <Typography className={classes.date}>
          {disbursmentItem.kind !== 'receipt' ? formatMoney(sumAmountAccounted) : '-'}
        </Typography>
      ),
      hide: kindEntry.length <= 1,
    },
    {
      id: 'credit',
      content: (
        <Typography className={classes.date}>
          {disbursmentItem.kind === 'receipt' ? formatMoney(sumAmountAccounted) : '-'}
        </Typography>
      ),
      hide: kindEntry.length <= 1,
    },
  ];

  return (
    <TableRow>
      {tableCells
        .filter((cell) => !cell.hide)
        .map((cell) => (
          <TableCell key={cell.id}>{cell.content}</TableCell>
        ))}
    </TableRow>
  );
};

export default DisbursmentRow;
