import graphQLClient from 'config/graphql-instance';
import { gql } from 'graphql-request';
import gqlRequest from 'graphql/generic';
import { DocumentPartyRequest, DocumentsType } from 'v2-types/order';

export type GQLCreateOrderFileData = {
  createOrderFileData: {
    files: DocumentsType[];
  };
};

export const createOrderFileData = async (payload: {
  id: string;
  dataId: string;
  kind: string;
  fileId: string;
}) => {
  const mutation = gql`
    mutation CreateOrderFileData(
      $id: ID!
      $dataId: ID!
      $fileId: ID!
      $kind: OrderFileDataKinds!
    ) {
      createOrderFileData(
        _id: $id
        data_id: $dataId
        file_id: $fileId
        kind: $kind
      ) {
         parties {
          name
          _id
          kinds
        }
        files {
          _id
          archives {
            _id
            files {
              _id
              data {
                _id
                kind
                address
                kinds
                name
              }
              download
              filename
              mtime
              size
              source
            }
            kinds
            name
          }
          data {
            _id
            address
            kind
            kinds
            name
          }
          description
          kind
          template
          per
          parts {
            description
            filename
            mtime
            size
            source
          }
        }
      }
    }
  `;

  const response = await graphQLClient().request<GQLCreateOrderFileData>(
    mutation,
    payload
  );

  return response;
};

export type GQLDeleteOrderFile = {
  deleteOrderFile: {
    files: DocumentsType[];
  };
};

export const deleteOrderFile = async (payload: {
  id: string;
  fileId: string;
}) => {
  const mutation = gql`
    mutation DeleteOrderFile(
		$id: ID!, 
		$fileId: ID!
	) {
      deleteOrderFile(
		_id: $id, 
		file_id: $fileId) {
        parties {
          name
          _id
          kinds
        }
        files {
          _id
          archives {
            _id
            files {
              _id
              data {
                _id
                kind
                address
                kinds
                name
              }
              download
              filename
              mtime
              size
              source
            }
            kinds
            name
          }
          data {
            _id
            address
            kind
            kinds
            name
          }
          description
          kind
          template
          per
          parts {
            description
            filename
            mtime
            size
            source
          }
        }
      }
    }
  `;

  const response = await graphQLClient().request<GQLDeleteOrderFile>(
    mutation,
    payload
  );

  return response;
};

export type GQLDeleteOrderFileData = {
  deleteOrderFileData: {
    files: DocumentsType[];
  };
};

export const deleteOrderFileData = async (payload: {
  id: string;
  dataId: string;
  kind: string;
  fileId: string;
}) => {
  const mutation = gql`
    mutation DeleteOrderFileData(
      $id: ID!
      $dataId: ID!
      $fileId: ID!
      $kind: OrderFileDataKinds!
    ) {
      deleteOrderFileData(
        _id: $id
        data_id: $dataId
        file_id: $fileId
        kind: $kind
      ) {
         parties {
          name
          _id
          kinds
        }
        files {
          _id
          archives {
            _id
            files {
              _id
              data {
                _id
                kind
                address
                kinds
                name
              }
              download
              filename
              mtime
              size
              source
            }
            kinds
            name
          }
          data {
            _id
            address
            kind
            kinds
            name
          }
          description
          kind
          template
          per
          parts {
            description
            filename
            mtime
            size
            source
          }
        }
      }
    }
  `;

  const response = await graphQLClient().request<GQLDeleteOrderFileData>(
    mutation,
    payload
  );

  return response;
};

export type GQLUpdateOrderFile = {
  updateOrderFile: {
    files: DocumentsType[];
  };
};

export const updateOrderFile = async (payload: {
  id: string;
  description?: string;
  fileId: string;
  parts?: string[];
}) => {
  const mutation = gql`
    mutation UpdateOrderFile(
      $id: ID!
      $fileId: ID!
      $description: String
      $parts: [String]
    ) {
      updateOrderFile(
        _id: $id
        file_id: $fileId
        description: $description
        parts: $parts
      ) {
        parties {
          name
          _id
          kinds
        }
        files {
          _id
          archives {
            _id
            files {
              _id
              data {
                _id
                kind
                address
                kinds
                name
              }
              download
              filename
              mtime
              size
              source
            }
            kinds
            name
          }
          data {
            _id
            address
            kind
            kinds
            name
          }
          description
          kind
          template
          per
          parts {
            description
            filename
            mtime
            size
            source
          }
        }
      }
    }
  `;

  const response = await graphQLClient().request<GQLUpdateOrderFile>(
    mutation,
    payload
  );

  return response;
};

export type GQLCreateOrderFile = {
  createOrderFile: {
    files: DocumentsType[];
  };
};

export const createOrderFile = async (payload: {
  id: string;
  kind: string;
  description: string;
  per?: string[];
  template?: string;
}) => {
  const mutation = gql`
    mutation CreateOrderFile(
      $id: ID!
      $kind: OrderFileKinds!
      $description: String!
      $template: String
      $per: [OrderPerKinds!]
    ) {
      createOrderFile(
        _id: $id
        kind: $kind
        description: $description
        template: $template
        per: $per
      ) {
        parties {
          name
          _id
          kinds
        }
        files {
          _id
          archives {
            _id
            files {
              _id
              data {
                _id
                kind
                address
                kinds
                name
              }
              download
              filename
              mtime
              size
              source
            }
            kinds
            name
          }
          data {
            _id
            address
            kind
            kinds
            name
          }
          description
          kind
          template
          per
          parts {
            description
            filename
            mtime
            size
            source
          }
        }
      }
    }
  `;

  const response = await graphQLClient().request<GQLCreateOrderFile>(
    mutation,
    payload
  );

  return response;
};

export type RequestPartyDocumentsParams = {
  id: string;
  requests: DocumentPartyRequest[];
  notBefore: string;
  expiresIn: string;
};

export type RequestPartyDocumentsReturn = {
  deleteOrderLedgerAssociation: {};
};

const mutationRequestOrderFiles = `
  mutation RequestOrderFiles($id: ID!, $requests: [OrderFileRequest!]!, $notBefore: String!, $expiresIn: String!) {
    requestOrderFiles(_id: $id, requests: $requests, not_before: $notBefore, expires_in: $expiresIn) {
      _id
    }
  }
`;

export const requestPartyDocuments = (params: RequestPartyDocumentsParams) => gqlRequest<RequestPartyDocumentsReturn, RequestPartyDocumentsParams>(
  mutationRequestOrderFiles,
  params
);

export type GQLDeleteOrderArchvie = {
  deleteOrderArchive: {
    _id: string,
    files: DocumentsType[]
  }
}

export const deleteOrderArchive = async (payload: {
  id: string,
  documentId: string,
  archiveId: string,
  fileId: string
}) => {
  const mutation = gql`
    mutation DeleteOrderArchive($id: ID!, $documentId: ID!, $archiveId: ID!, $fileId: ID!) {
    deleteOrderArchive(_id: $id, document_id: $documentId, archive_id: $archiveId, file_id: $fileId) {
      _id
      parties {
          name
          _id
          kinds
      }
      files {
        _id
        archives {
          _id
          files {
            _id
            data {
              _id
              address
              kind
              kinds
              name
            }
            download
            filename
            source
            mtime
            size
          }
          kinds
          name
        }
        data {
          _id
          address
          kind
          kinds
          name
        }
        description
        kind
        parts {
          description
          filename
          mtime
          size
          source
        }
        per
        template
      }
    }
  }
  `;

  const response = await graphQLClient().request<GQLDeleteOrderArchvie>(mutation, payload);
  return response;
};

export type GQLUpdateOrderArchive = {
  updateOrderArchive: {
    _id: string,
    files: DocumentsType[]
  }
}

export const updateOrderArchive = async (payload: {
  id: string,
  documentId: string,
  archiveId: string,
  fileId: string,
  newName: string
}) => {
  const mutation = gql`
    mutation UpdateOrderArchive($id: ID!, $documentId: ID!, $archiveId: ID!, $fileId: ID!, $newName: String!) {
      updateOrderArchive(_id: $id, document_id: $documentId, archive_id: $archiveId, file_id: $fileId, new_name: $newName) {
        _id
        parties {
          name
          _id
          kinds
        }
        files {
          _id
          archives {
            _id
            files {
              _id
              data {
                _id
                address
                kind
                kinds
                name
              }
              download
              filename
              source
              mtime
              size
            }
            kinds
            name
          }
          data {
            _id
            address
            kind
            kinds
            name
          }
          description
          kind
          parts {
            description
            filename
            mtime
            size
            source
          }
          per
          template
        }
      }
    }
  `;

  const response = await graphQLClient().request<GQLUpdateOrderArchive>(mutation, payload);
  return response;
};

export type GqlGenerateFiles = {
	generateOrderFiles: {
		_id:string,
	  }
	}

export const genereateOrderFiles = async (payload:{
	id: string
}) => {
  const mutation = gql`
	mutation GenerateOrderFiles($id: ID!) {
		generateOrderFiles(_id: $id) {
		  _id
		}
	  }
	`;
  const response = await graphQLClient().request<GqlGenerateFiles>(mutation, payload);
  return response;
};
