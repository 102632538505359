import gqlRequest from 'graphql/generic';
import { AccountingType } from 'v2-types/order';
import { createEntryForm } from '../types';

const queryCreateEntry = `
mutation HelpOrderLinkedEntry($id: ID!, $code: OrderLedgerCodes!, $description: String!, $entryDate: String!, $kind: OrderLedgerKinds!, $targets: [OrderLinkedEntryContent!]!) {
  helpOrderLinkedEntry(_id: $id, code: $code, description: $description, entry_date: $entryDate, kind: $kind, targets: $targets) {
		accounting {
			_id
			code
			description
			entry_date
			amount
			letter
			number
			kind
			involved_parties {
				_id
				name
				order_kinds
				kind
				amount
				accounted_amount
			}
			accounted_for {
				amount
				record {
					_id
					description
					letter
					number
					amount
					involved_parties {
						_id
						name
						kind
						order_kinds
						accounted_amount
					}
				}
			}
		}
	}
}
`;

type createLinkParams = createEntryForm & {
  id: string;
};

type createLinkReturn = {
  helpOrderLinkedEntry: {
    accounting: AccountingType[] | null;
  };
};

export const createLedgerLink = (params: createLinkParams) =>
  gqlRequest<createLinkReturn, createLinkParams>(queryCreateEntry, params);

type editentryReturn = {
  updateOrderLedgerEntry: {
      accounting: AccountingType[] | null;
  };
}

const queryEditEntry = `
mutation UpdateOrderLedgerEntry($id: ID!, $accountingId: ID!, $data: OrderLedgerEntryUpdateContent!) {
  updateOrderLedgerEntry(_id: $id, accounting_id: $accountingId, data: $data) {
		accounting {
			_id
			code
			description
			entry_date
			amount
			letter
			number
			kind
			involved_parties {
				_id
				name
				order_kinds
				kind
				amount
				accounted_amount
			}
			accounted_for {
				amount
				record {
					_id
					description
					letter
					number
					amount
					involved_parties {
						_id
						name
						kind
						order_kinds
						accounted_amount
					}
				}
			}
		}
	}
}
`;

type editEntryParams = {
	id: string,
  accountingId: string,
  data: {
    code: string,
    description: string,
    entry_date: string,
  }
}

export const mutationEditEntry = (params:editEntryParams) => gqlRequest<editentryReturn, editEntryParams>(queryEditEntry, params);
