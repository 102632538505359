import { useCreateLoanInterest } from 'hooks/useCreateLoanInterest';
import { useGetListings } from 'hooks/useGetListings';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import useAlert from 'utils/alert';
import { HUD_1 } from 'utils/constants';
import { InterestKindEnum, Loan } from 'v2-types/order';

import { CreateInterestForm } from '../../types';
import { filterKindList } from './utils/filterKindList';

type Params = {
  onClose: () => void,
  onSuccess: (data: Loan[], loanIdToSelect: string) => void,
  orderType: string
}
export const useCreateInterestModal = ({ onClose, onSuccess, orderType }: Params) => {
  const { id: orderId, loanId } = useParams<{ id: string, loanId: string }>();
  const showAlert = useAlert();
  const createInterestInitialValues: CreateInterestForm = {
    kind: orderType === HUD_1 ? InterestKindEnum.fixed : InterestKindEnum.only
  };
  const createInterestForm = useForm<CreateInterestForm>({ defaultValues: createInterestInitialValues, mode: 'onChange' });

  const { data: kindListingResponse, isLoading: isLoadingListing } = useGetListings({ code: "allowed_interest_blocks" });
  const kindListing = filterKindList({
    kindList: kindListingResponse?.getListing.entries ?? [],
    orderType
  });

  const [createInterest, { isLoading: isCreateInterestLoading }] = useCreateLoanInterest({
    queryConfig: {
      onSuccess: (response) => {
        const { loans } = response.createOrderLoanInterest;
        if (loans) {
          const updatedLoan = response.createOrderLoanInterest.loans.find((loan) => loan._id === loanId);
          onSuccess(loans, updatedLoan?._id || '');
        }

        showAlert('Interest successfully created', 'success');
        onClose();
      },
      onError: () => {
        showAlert('Unexpected error occurred while trying to create a new interest', 'error');
      }
    }
  });

  const onConfirm = createInterestForm.handleSubmit((data) => {
    createInterest({
      orderId,
      loanId,
      ...data
    });
  });

  return {
    createInterestForm,
    onConfirm,
    isCreateInterestLoading,
    isLoadingListing,
    kindListing
  };
};
