import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Grid, makeStyles, Paper } from '@material-ui/core';
import React, { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useAlert from 'utils/alert';
import useDeleteEntry from 'utils/ledger/use-delete-ledger-entry';

import Recording from './recording';
import DocumentModalCalculation from './document-fees-modal/document-modal-calculation';
import { AccountingType } from 'v2-types/order';

const useStyles = makeStyles((theme: any) => ({
  container: { padding: theme.spacing(2) },
  row: {
    display: 'flex',
    flexDirection: 'row',
    '& > *': { marginLeft: theme.spacing(2) },
  },
  add: {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    alignSelf: 'flex-end',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.text.primary,
    },
    marginBottom: theme.spacing(2),
  },
  iconSpace: { marginRight: theme.spacing(1) },
}));

type DocumentsType = {
  handleIsLoading: (value: boolean) => void;
  accounting: AccountingType[],
  updateAccounting: (data: AccountingType[]) => void,
  letterDocuments: string
};

const DocumentsDetails: FC<DocumentsType> = ({
  handleIsLoading,
  accounting,
  updateAccounting,
  letterDocuments
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openAddModal, setOpenAddModal] = useState(false);
  const { id } = useParams<{ id: string }>();
  const showAlert = useAlert();

  const accountingData = accounting.filter((charge) => charge.code === 'document');

  const { deleteEntry, deleteEntryResponse } = useDeleteEntry((data) => {
    deleteEntryResponse.reset();
    showAlert('Document Deleted', 'success');
    updateAccounting(data.deleteOrderLedgerEntry.accounting || []);
    handleIsLoading(false);
  });

  return (
    <>
      <Paper className={classes.container}>
        <Grid item xs={12}>
          {accountingData.map((acc) => (
            <Recording
              recording={acc}
              key={acc._id}
              handleDelete={() => {
                deleteEntry({
                  id,
                  accountingId: acc._id,
                  filters: { accounting: letterDocuments },
                });
                handleIsLoading(true);
              }}
            />
          ))}
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.row}>
            <Button
              className={classes.add}
              variant="outlined"
              onClick={() => setOpenAddModal(true)}
            >
              <FontAwesomeIcon icon="plus" size="1x" className={classes.iconSpace} />
              {t('accounting:add-document-entry')}
            </Button>
          </Box>
        </Grid>
      </Paper>
      {openAddModal !== null && (
        <DocumentModalCalculation
          open={!!openAddModal}
          onClose={() => {
            setOpenAddModal(false);
          }}
          handleSuccess={(accountingInfo) => {
            updateAccounting(accountingInfo);
            handleIsLoading(false);
          }}
          letter={letterDocuments}
        />
      )}
    </>
  );
};

export default memo(DocumentsDetails);
