import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  capitalize,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import TableComponent from 'components/Table';
import { EarnestAndCommissions } from './components/EarnestAndCommissions/index';
import React, { FC } from 'react';
import { Control } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { OrderProperty, PropertyLoanType } from 'types/order';
import { LEDGER_CODE_LOAN_AMOUNT, REFINANCE, LEDGER_CODE_PURCHASE_PRICE } from 'utils/constants';
import { formatMoney } from 'utils/helpers';
import { AccountingType } from 'v2-types/order';

import { AddAndUpdatePartyLedgerEntry } from '../../../../../components/add-and-update-party-ledger-modal/add-and-update-party-ledger-entry';
import { PropertyNumberField } from '../PropertyNumberField';
import { useTabAmountHooks } from './tab-amount-hooks';

const useStyles = makeStyles((theme: any) => ({
  container: { marginTop: theme.spacing(2), marginBottom: theme.spacing(2) },
  primarycolorfonts: {
    fontWeight: 500,
    color: theme.palette.tab.offselected,
  },
  placeholderText: {
    color: theme.palette.tab.offselected,
    fontWeight: 500,
    textAlign: 'center',
    marginTop: theme.spacing(5),
  },
}));

type TabAmountsProps = {
  propertyId: string,
  control: Control<OrderProperty, object>,
  selectedProperty: OrderProperty,
  transactionType: string
};

export const TabAmounts: FC<TabAmountsProps> = ({
  propertyId,
  control,
  selectedProperty,
  transactionType
}) => {
  const classes = useStyles();

  const {
    orderId,
    accountingEntries,
    purchasePriceEntries,
    openPartyLedgerModal,
    setOpenPartyLedgerModal,
    entryLedger,
    partyLedgerEntry,
    setPartyLedgerEntry,
    onUpdatePurchasePriceEntries,
    onRemoveOrderPartyEntry,
    t,
  } = useTabAmountHooks({ control });

  const history = useHistory();

  return (
    <Grid container spacing={3} className={classes.container}>
      <Grid item container spacing={2}>
        {accountingEntries.filter((entry) => {
          const { code } = entry as AccountingType;
          if (code.includes(LEDGER_CODE_PURCHASE_PRICE) && transactionType === REFINANCE) return false;

          return true;
        }).map((entry, index) => {
          const { code } = entry as AccountingType;
          const label = capitalize(code.replace(`_${propertyId}`, '').replace('_', ' '));

          return (
            <Grid item xs key={entry.id}>
              <PropertyNumberField
                control={control}
                name={`accounting.${index}.amount`}
                label={label}
                decimalScale={2}
                thousandSeparator
                prefix="$"
                isMoney
              />
            </Grid>
          );
        })}
      </Grid>
      <Grid item container>
        <Typography variant="h6" className={classes.primarycolorfonts}>
          Loans
        </Typography>
        {selectedProperty.loans && selectedProperty.loans?.length > 0 && (
          <TableComponent
            disabledBorderLeft
            cellAlignment="left"
            columns={{
              actions: {
                header: { title: 'Actions', align: 'center', width: '80px' },
                renderer: ({ row }) => (
                  <Box display="flex" justifyContent="center">
                    <IconButton
                      style={{ padding: 0 }}
                      onClick={() => history.push(`/orders/details/${orderId}/loans/${row._id}`)}
                    >
                      <FontAwesomeIcon icon="external-link-alt" size="xs" />
                    </IconButton>
                  </Box>
                ),
              },
              numer: {
                header: { title: t('orders:loan-number'), align: 'left', width: 'auto' },
                renderer: ({ row }) => (
                  <Link to={`/orders/details/${orderId}/loans/${row._id}`}>{row.loan_number}</Link>
                ),
              },
              loanAmount: {
                header: { title: 'Amount', align: 'left' },
                // eslint-disable-next-line react/no-unused-prop-types
                renderer: ({ row }: { row: PropertyLoanType }) => {
                  const loanAmountEntry = row.accounting.find((entry) =>
                    entry.code.startsWith(LEDGER_CODE_LOAN_AMOUNT)
                  );
                  return <>{formatMoney(loanAmountEntry?.amount ?? 0)}</>;
                },
              },
            }}
            dataSet={selectedProperty.loans || []}
          />
        )}
        {(!selectedProperty.loans || selectedProperty.loans.length === 0) && (
          <Typography className={classes.placeholderText}>There are not loans added yet</Typography>
        )}
      </Grid>
      {transactionType !== REFINANCE && (
        <EarnestAndCommissions
          purchasePriceEntries={purchasePriceEntries}
          onRemoveOrderPartyEntry={onRemoveOrderPartyEntry}
          setOpenPartyLedgerModal={setOpenPartyLedgerModal}
          setPartyLedgerEntry={setPartyLedgerEntry}
          t={t}
        />
      )}
      {entryLedger && openPartyLedgerModal && (
        <AddAndUpdatePartyLedgerEntry
          kindAllowedAssociationList={['earnest', 'commission', 'option', 'owner']}
          entryLedger={entryLedger}
          partyLedgerEntry={partyLedgerEntry}
          handleClose={() => {
            setPartyLedgerEntry(undefined);
            setOpenPartyLedgerModal(false);
          }}
          handleSuccess={onUpdatePurchasePriceEntries}
        />
      )}
    </Grid>
  );
};
