/* eslint-disable react-hooks/exhaustive-deps */
import { debounce } from 'debounce';
import {
  createOrderFile,
  createOrderFileData,
  deleteOrderArchive,
  deleteOrderFile,
  deleteOrderFileData,
  genereateOrderFiles,
  updateOrderArchive,
  updateOrderFile,
} from 'graphql/documents/mutations';
import {
  getOrderDocuments,
  getTemplatesResponse,
  GQLGetOrderDocuments,
  GQLGetTemplates,
} from 'graphql/documents/queries';
import {
  useCallback, useEffect, useMemo, useRef, useState
} from 'react';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { useParams } from 'react-router-dom';
import useAlert from 'utils/alert';
import { getTokens } from 'utils/search';
import { useWordHighlight } from 'v2-hooks/useWordHighlight';
import { DocumentsType } from 'v2-types/order';

import {
  ArchiveUpdate,
  AssociationType,
  openModalType,
  OrderDocumentsType,
  SearchParams,
  SelectedFilesType,
} from './types';
import { searchDocuments } from './utils/filterDocuments';
import { orderDocumentsAlphabetically } from './utils/orderDocumentsAlphabetically';

const useDocuments = () => {
  const { id: orderId } = useParams<{ id: string }>();
  const showAlert = useAlert();

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const searchParamsRef = useRef<SearchParams>({
    query: '',
    party: '',
    property: '',
  });
  const [regexSearchWords, setRegexSearchWords] = useState<string>('');

  const htmlDocumentsContainerRef = useRef<HTMLDivElement | null>(null);
  const { searchWordClass } = useWordHighlight({
    container: htmlDocumentsContainerRef.current,
    regexSearchWords,
  });

  const [orderDocuments, setOrderDocuments] = useState<OrderDocumentsType>({
    files: [],
    parties: [],
    estates: [],
  });
  const documentsRef = useRef<DocumentsType[]>();

  const [selectedFiles, setSelectedFiles] = useState<SelectedFilesType[]>([]);

  const [showAlerUpdate, setShowAlertUpdate] = useState(false);

  const [open, setOpen] = useState<openModalType>({
    status: false,
    id: '',
    location: '',
    data: undefined,
  });
  const queryCache = useQueryCache();
  const debounceSearch = useCallback(
    debounce(() => {
      setOpenBackdrop(true);
      const { query, party, property } = searchParamsRef.current;
      if (query.length <= 0 && party.length <= 0 && property.length <= 0) {
        setOrderDocuments((prevData) => ({
          ...prevData,
          files: orderDocumentsAlphabetically(documentsRef.current ?? []),
        }));
        setOpenBackdrop(false);
        setRegexSearchWords('');
        return;
      }

      const { queryTokens, partyTokens, propertyTokens } = getTokens({
        query,
        party,
        property,
      });

      let documents: DocumentsType[] = [];
      let searchWords = '';
      if (documentsRef.current) {
        documents = searchDocuments({
          documents: documentsRef.current,
          queryTokens,
          partyTokens,
          propertyTokens,
        });

        const words = [
          ...(queryTokens || []),
          ...(partyTokens || []),
          ...(propertyTokens || []),
        ].join('|');

        searchWords = `(${words})`;
      }

      setOpenBackdrop(false);
      setOrderDocuments((prevData) => ({
        ...prevData,
        files: orderDocumentsAlphabetically(documents),
      }));
      setRegexSearchWords(searchWords);
    }, 1000),
    []
  );

  const [createFile] = useMutation(createOrderFile, {
    onSuccess: (data) => {
      documentsRef.current = data.createOrderFile.files;
      debounceSearch();
      showAlert('Successful document creation', 'success');
      setOpenBackdrop(false);
    },
    onError: () => {
      showAlert('An error occurred, try again', 'error');
      setOpenBackdrop(false);
    },
  });

  const [createFileData] = useMutation(createOrderFileData, {
    onSuccess: (data) => {
      documentsRef.current = data.createOrderFileData.files;
      debounceSearch();
      showAlert('Successful Association creation', 'success');
      setOpenBackdrop(false);
    },
    onError: () => {
      showAlert('An error occurred, try again', 'error');
      setOpenBackdrop(false);
    },
  });

  const [deleteFileData] = useMutation(deleteOrderFileData, {
    onSuccess: (data) => {
      documentsRef.current = data.deleteOrderFileData.files;
      debounceSearch();
      showAlert('Association deletion successful', 'success');
      setOpenBackdrop(false);
    },
    onError: () => {
      showAlert('An error occurred, try again', 'error');
      setOpenBackdrop(false);
    },
  });

  const [updateFile] = useMutation(updateOrderFile, {
    onSuccess: (data) => {
      documentsRef.current = data.updateOrderFile.files;
      debounceSearch();

      showAlert('Document update successful', 'success');
      setOpenBackdrop(false);
    },
    onError: () => {
      showAlert('An error occurred, try again', 'error');
      setOpenBackdrop(false);
    },
  });

  const [deleteFile] = useMutation(deleteOrderFile, {
    onSuccess: (data) => {
      documentsRef.current = data.deleteOrderFile.files;
      debounceSearch();
      showAlert('Document deletion successful', 'success');
      setOpenBackdrop(false);
    },
    onError: () => {
      showAlert('An error occurred, try again', 'error');
      setOpenBackdrop(false);
    },
  });

  const [deleteArchive] = useMutation(deleteOrderArchive, {
    onSuccess: (data) => {
      documentsRef.current = data.deleteOrderArchive.files;
      debounceSearch();
      showAlert('Archive was successfully deleted', 'success');
      setOpenBackdrop(false);
    },
    onError: () => {
      showAlert('An error occurred, try again', 'error');
      setOpenBackdrop(false);
    },
  });

  const [renameArchive] = useMutation(updateOrderArchive, {
    onSuccess: (data) => {
      documentsRef.current = data.updateOrderArchive.files;
      debounceSearch();
      showAlert('Archive was successfully renamed', 'success');
      setOpenBackdrop(false);
    },
    onError: () => {
      showAlert('An error occurred, try again', 'error');
      setOpenBackdrop(false);
    },
  });

  const handleToggleModals = (
    id: string,
    location: string = '',
    data: ArchiveUpdate | undefined | DocumentsType = undefined
  ) => {
    setOpen({ status: !open.status, id, location, data });
  };

  const queryGEtDocs = useQuery<GQLGetOrderDocuments>(
    ['order-documents', orderId],
    () => getOrderDocuments({ id: orderId }),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setOrderDocuments({
          ...orderDocuments,
          files: orderDocumentsAlphabetically(data?.getOrder.files ?? []),
          estates:
            data?.getOrder.estates.map(
              (estate) => ({
                _id: estate._id,
                name: estate.address.address,
              } as AssociationType)
            ) ?? [],
          parties:
            data?.getOrder.parties.map(
              (party) => ({
                _id: party._id,
                name: party.name,
                kinds: party.kinds,
              } as AssociationType)
            ) ?? [],
        });
        if (showAlerUpdate) {
          setShowAlertUpdate(false);
		  }
        documentsRef.current = data.getOrder.files ?? [];
      },
    }
  );

  const templateDocsResponse = useQuery<GQLGetTemplates>(
    ['templates'],
    () => getTemplatesResponse(),
    { refetchOnWindowFocus: false }
  );

  const { getTemplates } = templateDocsResponse.data || {};

  const handleAddData = (payload) => {
    setOpenBackdrop(true);
    createFileData({ ...payload, id: orderId });
  };

  const handleRemoveData = (payload) => {
    setOpenBackdrop(true);
    deleteFileData({ ...payload, id: orderId });
  };

  const handleRemoveFile = (fileId) => {
    setOpenBackdrop(true);
    deleteFile({ fileId, id: orderId });
  };

  const handleupdateFile = (
    description: string | undefined = undefined,
    fileId: string,
    parts?: string[]
  ) => {
    setOpenBackdrop(true);
    updateFile({
      id: orderId,
      description,
      fileId,
      parts,
    });
  };

  const handleCreateFile = (payload) => {
    setOpenBackdrop(true);
    createFile({
      ...payload,
      id: orderId,
    });
  };

  const handleRemoveArchive = ({
    documentId,
    archiveId,
    fileId,
  }: {
    documentId: string;
    archiveId: string;
    fileId: string;
  }) => {
    setOpenBackdrop(true);
    deleteArchive({
      id: orderId,
      documentId,
      archiveId,
      fileId,
    });
  };

  const handleRenameArchive = ({
    documentId,
    archiveId,
    fileId,
    newName,
  }: {
    documentId: string;
    archiveId: string;
    fileId: string;
    newName: string;
  }) => {
    setOpenBackdrop(true);
    renameArchive({
      id: orderId,
      documentId,
      archiveId,
      fileId,
      newName,
    });
  };

  const handleCheckboxChange = (file: SelectedFilesType) => {
    if (selectedFiles.some((selectedFile) => selectedFile.id === file.id)) {
      setSelectedFiles(selectedFiles.filter((item) => item.id !== file.id));
    } else {
      setSelectedFiles([...selectedFiles, file]);
    }
  };
  const handleUpdate = () => {
    queryCache.invalidateQueries(['order-documents', orderId]);
  };
  const allFilesinDocuments = useMemo(
    () => orderDocuments.files.flatMap((documents) => documents.archives.flatMap((archives) => archives.files.map((file) => ({
      name: file.filename,
      source: file.source,
    })))),
    [orderDocuments.files]
  );

  const handleToggleSelectAllFiles = () => {
    if (selectedFiles.length > 0) {
      setSelectedFiles([]);
    } else {
      const files: SelectedFilesType[] = [
        ...orderDocuments.files.flatMap((document) => [
          ...document.archives.flatMap((archive) => archive.files.map((file) => ({
            id: file.source,
            name: file.filename,
          }))),
        ]),
      ];
      setSelectedFiles(files);
    }
  };

  const [generateOrderFiles, generateFilesResponse] = useMutation(genereateOrderFiles, {
    onSuccess: (data) => {
      generateFilesResponse.reset();
      if (data.generateOrderFiles._id) {
        setShowAlertUpdate(true);
      }
    },
    onError: () => {
      generateFilesResponse.reset();
      showAlert('An error occurred, try again', 'error');
	  },
  });

  const onSearch = ({
    value,
    type = 'query',
  }: {
    value: string;
    type: keyof SearchParams;
  }) => {
    debounceSearch.clear();
    searchParamsRef.current[type] = value;
    debounceSearch();
  };

  useEffect(() => {
    if (queryGEtDocs.isLoading || queryGEtDocs.isFetching) {
      showAlert('Generating documents...', 'info');
    }
  }, [queryGEtDocs]);

  return {
    documents: orderDocuments.files,
    estates: orderDocuments.estates,
    parties: orderDocuments.parties,
    open,
    handleToggleModals,
    orderId,
    handleAddData,
    handleRemoveData,
    handleRemoveFile,
    handleupdateFile,
    handleCreateFile,
    handleRemoveArchive,
    handleRenameArchive,
    getTemplates,
    handleCheckboxChange,
    selectedFiles,
    openBackdrop,
    allFilesinDocuments,
    searchParamsRef,
    onSearch,
    htmlDocumentsContainerRef,
    searchWordClass,
    handleToggleSelectAllFiles,
    generateOrderFiles,
    showAlerUpdate,
    handleUpdate
  };
};

export default useDocuments;
