import { makeStyles, Typography } from '@material-ui/core';
import Switch from 'components/switch';
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ProjectedPaymentsForm } from '../services';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  }
});

type InterestOnlyFieldsProps = {
  methods: UseFormReturn<ProjectedPaymentsForm, any, undefined>
};

const InterestOnlyFields = ({ methods }: InterestOnlyFieldsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <span className={classes.root}>
      <Controller
        control={methods.control}
        name="interest_only"
        render={({ field }) => (
          <>
            <Switch label="" checked={field.value} handleOnChange={(e) => field.onChange(e.target.checked)} />
            <Typography>{t(`projectedPayments:${field.value ? 'interestOnly' : 'no'}`)}</Typography>
          </>
        )}
      />

    </span>
  );
};

export default InterestOnlyFields;
