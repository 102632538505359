import {
  capitalize,
  Dialog, DialogActions, DialogContent, Grid, Typography
} from '@material-ui/core';
import { ContainedButton } from 'components/ContainedButton';
import DatePicker from 'components/date-picker/date-picker';
import NumberField from 'components/number-field';
import SelectField from 'components/select-field';
import TextField from 'components/text-field';
import React, { FC } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { InterestKindEnum, Loan } from 'v2-types/order';

import { PercentField } from './components/percentField';
import { useCreateInterestModal } from './hooks';
import { useStyles } from './styles';

type Props = {
  onClose: () => void,
  onSuccess: (data: Loan[], loanIdToSelect: string) => void,
  orderType: string
}

export const CreateInterestModal: FC<Props> = ({
  onClose,
  onSuccess,
  orderType
}) => {
  const classes = useStyles();
  const {
    createInterestForm,
    onConfirm,
    isCreateInterestLoading,
    kindListing,
    isLoadingListing
  } = useCreateInterestModal({ onClose, onSuccess, orderType });

  const { control, reset } = createInterestForm;
  const { kind: kindSelected } = useWatch({ control });

  return (
    <Dialog open fullWidth>
      <DialogContent style={{ padding: 0 }}>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="h5" className={classes.title}>
              Create Loan Interest
            </Typography>
          </Grid>
          <Grid item container className={classes.container}>
            <Controller
              control={control}
              name="kind"
              render={({ field }) => (
                <SelectField
                  label="Kind"
                  value={field.value}
                  handleBlur={field.onBlur}
                  handleChange={(e) => {
                    reset({ kind: e.target.value as InterestKindEnum });
                  }}
                  data={kindListing}
                  dataKey="code"
                  dataValue="code"
                  dataText="description"
                  disabled={isLoadingListing}
                />
              )}
            />

            {(kindSelected === InterestKindEnum.only || kindSelected === InterestKindEnum.fixed || kindSelected === InterestKindEnum.variable) && (
              <PercentField
                control={control}
                name="rate"
                label="Rate"
              />
            )}

            {kindSelected === InterestKindEnum.adjustable && (
              <>
                <Controller
                  control={control}
                  name="index_name"
                  render={({ field }) => (
                    <TextField
                      color="secondary"
                      label={<Typography variant="body2">Index Name</Typography>}
                      name="index_name"
                      handleBlur={field.onBlur}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
                <PercentField
                  control={control}
                  name="margin"
                  label="Margin"
                />
              </>
            )}

            {kindSelected === InterestKindEnum.conversion && (
              <>
                <Controller
                  control={control}
                  name="fee"
                  render={({ field }) => (
                    <NumberField
                      color="secondary"
                      label="Fee"
                      name="fee"
                      handleBlur={field.onBlur}
                      value={field.value}
                      onChange={(e) => {
                        let value = Number(e.target.value);
                        if (Number.isNaN(value)) {
                          value = 0;
                        }
                        field.onChange(value);
                      }}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="period_start"
                  render={({ field }) => (
                    <DatePicker
                      label="Period Start"
                      name="period-start"
                      error={false}
                      handleBlur={field.onBlur}
                      value={field.value || ''}
                      onChange={(_, value) => {
                        const date = typeof value === 'string' ? new Date(value).toISOString().split('T')[0] : value;
                        field.onChange(date);
                      }}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="period_end"
                  render={({ field }) => (
                    <DatePicker
                      label="Period End"
                      name="period-end"
                      error={false}
                      handleBlur={field.onBlur}
                      value={field.value || ''}
                      onChange={(_, value) => {
                        const date = typeof value === 'string' ? new Date(value).toISOString().split('T')[0] : value;
                        field.onChange(date);
                      }}
                    />
                  )}
                />
              </>
            )}

            {kindSelected === InterestKindEnum.first && (
              <>
                <Controller
                  control={control}
                  name="change_date"
                  render={({ field }) => (
                    <DatePicker
                      label="Change Date"
                      name="change date"
                      error={false}
                      handleBlur={field.onBlur}
                      value={field.value || ''}
                      onChange={(_, value) => {
                        const date = typeof value === 'string' ? new Date(value).toISOString().split('T')[0] : value;
                        field.onChange(date);
                      }}
                    />
                  )}
                />

                <PercentField
                  control={control}
                  name="change_min"
                  label="Change Min"
                />

                <PercentField
                  control={control}
                  name="change_max"
                  label="Change Max"
                />
              </>
            )}

            {kindSelected === InterestKindEnum.subsequent && (
              <>
                <PercentField
                  control={control}
                  name="change_max"
                  label="Change Max"
                />

                <Controller
                  control={control}
                  name="months"
                  render={({ field }) => (
                    <NumberField
                      color="secondary"
                      label="Months"
                      name="months"
                      handleBlur={field.onBlur}
                      value={field.value}
                      onChange={(e) => {
                        let value = Number(e.target.value);
                        if (Number.isNaN(value)) {
                          value = 0;
                        }
                        field.onChange(value);
                      }}
                    />
                  )}
                />
                <PercentField
                  control={control}
                  name="rate_min"
                  label="Rate Min"
                />
                <PercentField
                  control={control}
                  name="rate_max"
                  label="Rate Max"
                />
              </>
            )}

          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actionButtons}>
        <ContainedButton
          text="Close"
          onClick={onClose}
          disabled={isCreateInterestLoading}
        />
        <ContainedButton
          text="Create"
          isLoading={isCreateInterestLoading || isLoadingListing}
          onClick={onConfirm}
        />
      </DialogActions>
    </Dialog>
  );
};
