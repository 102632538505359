import React, { FC } from 'react';
import { DocumentsType } from 'v2-types/order';

import { AssociationType } from '../../types';
import { useRequestDocuments } from './request-documents-modal-hooks';
import { RequestDocumentsModalScene } from './request-documents-modal-scene';

type RequestDocumentsModalProps = {
  open: boolean;
  parties: AssociationType[];
  documents: DocumentsType[];
  handleClose: () => void;
};

export const RequestDocumentsModal: FC<RequestDocumentsModalProps> = ({
  open,
  parties,
  documents,
  handleClose,
}) => {
  const {
    t,
    isRequestDocumentsLoading,
    control,
    getValues,
    onSendRequest,
    isFormValid,
    errors
  } = useRequestDocuments({
    parties,
    documents,
    onClose: handleClose,
  });
  return (
    <RequestDocumentsModalScene
      t={t}
      isOpen={open}
      isFormValid={isFormValid}
      control={control}
      getValues={getValues}
      handleClose={handleClose}
      isRequestDocumentsLoading={isRequestDocumentsLoading}
      onSendRequest={onSendRequest}
      errors={errors}
    />
  );
};
