import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: any) => ({
  iconSize: {
    padding: 0, fontSize: 22
  }
}));

type ActionButtonProps = {
  shouldCollapse?: boolean;
  handleCollapse?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  handleAdd?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  handleEdit?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  handleDelete?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  disableDelete?: boolean;
  handleChain?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  handleParties?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  handleTaxable?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  handleIndividualPayeePayer?: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

const Actionbuttons = ({
  shouldCollapse,
  handleCollapse,
  handleAdd,
  handleEdit,
  handleDelete,
  handleChain,
  handleParties,
  handleTaxable,
  handleIndividualPayeePayer,
  disableDelete
}: ActionButtonProps) => {
  const classes = useStyles();
  return (
    <Box
      style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'row', gap: '2px' }}
    >
      {handleCollapse && (
        <IconButton className={classes.iconSize} onClick={handleCollapse}>
          {shouldCollapse ? (
            <FontAwesomeIcon icon="arrow-alt-circle-up" size="xs" />
          ) : (
            <FontAwesomeIcon icon="arrow-alt-circle-down" size="xs" />
          )}
        </IconButton>
      )}
      {handleAdd && (
        <IconButton className={classes.iconSize} onClick={handleAdd}>
          <FontAwesomeIcon icon="plus-circle" size="xs" />
        </IconButton>
      )}
      {handleEdit && (
        <IconButton className={classes.iconSize} onClick={handleEdit}>
          <FontAwesomeIcon icon="edit" size="xs" />
        </IconButton>
      )}
      {handleDelete && (
        <IconButton disabled={disableDelete} className={classes.iconSize} onClick={handleDelete}>
          <FontAwesomeIcon icon="trash" size="xs" />
        </IconButton>
      )}
      {handleChain && (
        <IconButton className={classes.iconSize} onClick={handleChain}>
          <FontAwesomeIcon icon="link" size="xs" />
        </IconButton>
      )}
      {handleParties && (
        <IconButton className={classes.iconSize} onClick={handleParties}>
          <FontAwesomeIcon icon="people-arrows" size="xs" />
        </IconButton>
      )}
      {handleTaxable && (
        <IconButton className={classes.iconSize} onClick={handleTaxable}>
          <FontAwesomeIcon icon="coins" size="xs" />
        </IconButton>
      )}
      {handleIndividualPayeePayer && (
        <IconButton className={classes.iconSize} onClick={handleIndividualPayeePayer}>
          <FontAwesomeIcon icon="user" size="xs" />
        </IconButton>
      )}
    </Box>
  );
};

Actionbuttons.defaultProps = {
  shouldCollapse: undefined,
  handleCollapse: undefined,
  handleAdd: undefined,
  handleEdit: undefined,
  handleDelete: undefined,
};

export default Actionbuttons;
