import { FormikErrors } from 'formik';
import { TFunction } from 'i18next';
import React from 'react';

import AmountFields from '../components/amount-fields';
import IncludesFields from '../components/includes-fields';
import InEscrowFields from '../components/InEscrowFields';
import InterestOnlyFields from '../components/interest-only-fields';
import PrincipalInterestAmountFields from '../components/principal-interest-amount-fields';
import { UseFormReturn } from 'react-hook-form';
import { AccountingType } from 'v2-types/order';
import TooltipInput from '../components/tooltip-input';
import { YearsRangesTable } from '../components/Years-ranges-table';

export type YearRange = {
  range: number[],
  amount: number,
  entryId: string
}

export type projectedPayments = {
  year_ranges: number[][],
  principal_kind: 'amount' | 'range';
  principal_amount: number;
  principal_min: number;
  principal_max: number;
  interest_only: boolean;
  mortgage_insurance: number;
  estimated_escrow: number;
  prorations_amount: number;
  prorations_period: 'month' | 'week';
  includes_taxes: boolean;
  includes_homeowners: boolean;
  includes_other: boolean;
  other_description: string;
  escrow_homeowners: boolean;
  escrow_taxes: boolean;
  escrow_other: boolean;
  mortgage_insurance_calculated?: number,
  estimated_escrow_calculated?: number
}

export type ProjectedPaymentsForm = Omit<projectedPayments, "year_ranges"> & {
  year_ranges: YearRange[]
}

export type PaymentsProps = {
  t: TFunction,
  setFieldValue: any;
  year_range_from: number;
  year_raneg_to: number;
  principal_interest_amount: number;
  interest_only: boolean;
  mortgage_insurance: number;
  estimated_escrow: number;
  principal_interest_min: number;
  principal_interest_max: number;
  principal_interest_type: 'amount' | 'range';
  errors: FormikErrors<any>;
};

export const getEstimatedTaxesRows = ({
  t,
  methods
}: { t: TFunction, methods: UseFormReturn<ProjectedPaymentsForm, any, undefined> }) => ({
  amount: {
    title: t('projectedPayments:amount'),
    render: <AmountFields methods={methods} />
  },
  includes: {
    title: t('projectedPayments:includes'),
    render: <IncludesFields methods={methods} />
  },
  inEscrow: {
    title: t('projectedPayments:inEscrow'),
    render: <InEscrowFields methods={methods} />
  }
});

export const getPaymentsRows = ({
  t,
  methods,
  onEditMortgage,
  onEditEstimated,
  calculatedMortgageValue,
  calculatedEstimatedEscrow
}: {
  t: TFunction,
  methods: UseFormReturn<ProjectedPaymentsForm, any, undefined>,
  onEditMortgage: any,
  onEditEstimated: any,
  calculatedMortgageValue: any,
  calculatedEstimatedEscrow: any
}) => ({
  year_ranges: {
    title: t('projectedPayments:years-range'),
    render: <YearsRangesTable methods={methods} />
  },
  principal_interest_amount: {
    title: t('projectedPayments:principalInterest'),
    render: <PrincipalInterestAmountFields methods={methods} />
  },
  interest_only: {
    title: t('projectedPayments:interestOnly?'),
    render: <InterestOnlyFields methods={methods} />
  },
  mortgage_insurance: {
    title: t('projectedPayments:mortgageInsurance'),
    render: (
      <TooltipInput
        t={t}
        onEditMortgage={onEditMortgage}
        methods={methods}
        name="mortgage_insurance"
        calculatedValue={calculatedMortgageValue}
      />
    ),
  },
  estimated_escrow: {
    title: t('projectedPayments:estimatedEscrow'),
    render: (
      <TooltipInput
        t={t}
        onEditMortgage={onEditEstimated}
        methods={methods}
        name="estimated_escrow"
        calculatedValue={calculatedEstimatedEscrow}
      />
    )
  },
});

export type accountingProjectedType = Pick<
  AccountingType,
  '_id' | 'code' | 'description' | 'kind' | 'letter' | 'amount' | 'per_month' | 'number' | 'amount_override' | 'months_advanced' | 'annuality' | 'months' | 'amount_calculated'
>