import { Box, Paper, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { OrderProperty } from 'types/order';

import { CreateLoanModal } from './components/create-loan-modal';
import { EscrowAmounts } from './components/escrow-amounts';
import { Header } from './components/header';
import { LoanContent } from './components/loan-content';
import { useLoansHooks } from './hooks';
import { useStyles } from './styles';

type Props = {
  properties: OrderProperty[],
  orderType: string
}

export const Loans: FC<Props> = ({
  properties,
  orderType
}) => {
  const classes = useStyles();

  const {
    loans,
    onSelectLoan,
    selectedLoan,
    loanForm,
    setModalData,
    modalData,
    onDeleteLoanInterest,
    isDeleteInterestLoading,
    updateLoansCache,
    onDeleteLoan,
    isDeleteLoanLoading,
    onSave,
    isUpdateLoanLoading,
    isLoansLoading,
    orderAccountingEscrowEntries,
    deleteAdditional,
    isDeleteAdditionalLoading,
    onClose,
    additionalFeesLetter,
    t,
    onSuccesEntryEdited,
    onSuccesPartyEntryEdited
  } = useLoansHooks();

  return (
    <div className={classes.rootContainer}>
      {orderAccountingEscrowEntries.length > 0 && (
        <Paper className={classes.escrowAmountsContainer}>
          <EscrowAmounts
            orderAccountingEscrowEntries={orderAccountingEscrowEntries}
            additionalFeesLetter={additionalFeesLetter}
          />
        </Paper>
      )}
      <Paper>
        <>
          <Header
            loans={loans}
            isDataLoading={isLoansLoading}
            selectedLoan={selectedLoan}
            onSelectLoan={onSelectLoan}
            setModalData={setModalData}
            t={t}
          />

          {loans.length > 0 ? (
            <LoanContent
              isLoansLoading={isLoansLoading}
              isUpdateLoanLoading={isUpdateLoanLoading}
              selectedLoan={selectedLoan}
              loanForm={loanForm}
              setModalData={setModalData}
              isDeleteInterestLoading={isDeleteInterestLoading}
              isDeleteLoanLoading={isDeleteLoanLoading}
              modalData={modalData}
              onCloseModal={onClose}
              onDeleteLoan={onDeleteLoan}
              onDeleteLoanInterest={onDeleteLoanInterest}
              updateLoansCache={updateLoansCache}
              onSave={onSave}
              deleteAdditional={deleteAdditional}
              isDeleteAdditionalLoading={isDeleteAdditionalLoading}
              onSuccesEntryEdited={onSuccesEntryEdited}
              onSuccesPartyEntryEdited={onSuccesPartyEntryEdited}
              orderType={orderType}
            />
          ) : (
            <Box className={classes.noLoansContent}>
              <Typography variant="h6" className={classes.noLoansText}>
                This order does not have any loans yet
              </Typography>
            </Box>
          )}
        </>
      </Paper>
      {modalData?.modal === 'createLoan' && (
        <CreateLoanModal
          onClose={onClose}
          properties={properties}
          onSuccess={updateLoansCache}
        />
      )}
    </div>
  );
};
