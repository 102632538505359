import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { accountingProjectedType, ProjectedPaymentsForm } from './services';
import { useMutation, useQuery } from 'react-query';
import { getOrderProjectedPayments } from './services/queries';
import { updateOrderProjectedPayments } from './services/mutations';
import useAlert from 'utils/alert';
import { useEffect, useRef, useState } from 'react';
import { capitalize } from 'utils/helpers';
import { useTranslation } from 'react-i18next';
import { useSocketAccountingEventInvalidator } from 'hooks/useSocketAccountingEventInvalidator';
import { updateLedgerEntries } from 'graphql/ledger/mutations';

const useLoanTerms = () => {
  const methods = useForm<ProjectedPaymentsForm>({
    defaultValues: {
      year_ranges: [{
        range: [0, 1],
        amount: 0,
        entryId: ""
      }],
      principal_kind: 'amount',
      principal_amount: 0,
      principal_min: 0,
      principal_max: 0,
      interest_only: false,
      estimated_escrow: 0,
      prorations_amount: 0,
      mortgage_insurance: 0,
      prorations_period: 'month',
      includes_taxes: false,
      includes_homeowners: false,
      includes_other: false,
      other_description: '',
      escrow_homeowners: false,
      escrow_taxes: false,
      escrow_other: false,
      estimated_escrow_calculated: 0,
      mortgage_insurance_calculated: 0
    }
  });
  const { id: orderId } = useParams<{ id: string }>();
  const showAlert = useAlert();
  const { t } = useTranslation();
  const [currentEntry, setCurrentEntry] = useState<accountingProjectedType | undefined>(undefined);
  const referenceMortgageValue = useRef(0);
  const referenceEstimatedValue = useRef(0);

  useSocketAccountingEventInvalidator({
    queries: [['order-projected-payemnts', orderId]]
  });

  const getProjectedPayments = useQuery(['order-projected-payemnts', orderId],
    () => getOrderProjectedPayments(orderId, {
      accounting: '/^(mortgage_insurance|estimated_escrow|mortgageinsurance_)/'
    }),
    {
      enabled: !!orderId,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data.getOrder?.projected_payments) {
          const {
            year_ranges,
            ...rest
          } = data.getOrder?.projected_payments;

          methods.reset({
            ...rest,
            year_ranges: year_ranges.map((yearRange, index) => {
              const accEntry = data.getOrder.accounting.find(entry => entry.code.includes(`mortgageinsurance_${index + 1}`));

              return ({
                range: yearRange,
                entryId: accEntry ? accEntry._id : "",
                amount: accEntry ? accEntry.amount : 0
              });
            })
          });
        }
        if (data.getOrder.accounting) {
          const estimated = data.getOrder.accounting.find(f => f.code.includes('estimated_escrow'));
          const mortgageInsurance = data.getOrder?.accounting?.find(entry => entry.code.includes('mortgage_insurance'));
          methods.setValue('estimated_escrow', estimated?.amount || 0);
          methods.setValue('mortgage_insurance', mortgageInsurance?.amount || 0);
          if (referenceEstimatedValue.current === 0) {
            referenceMortgageValue.current = mortgageInsurance?.amount_calculated || 0;
          }
          if (referenceEstimatedValue.current === 0) {
            referenceEstimatedValue.current = estimated?.amount_calculated || 0;
          }
        }
      }
    });

  const [updateProjectedPayments, updateOrderProjectedResponse] = useMutation(updateOrderProjectedPayments, {
    onSuccess: () => {
      updateOrderProjectedResponse.reset();
      showAlert('Order Updated', 'success');
    },
    onError: () => { showAlert(capitalize(t('dialogs:there-is-an')), 'error'); }
  });

  useEffect(() => {
    if (getProjectedPayments.isFetching && getProjectedPayments.isLoading) {
      showAlert('Updating...', 'info');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProjectedPayments.isFetching, getProjectedPayments.isLoading]);

  const [
    updateAccountingEntries,
    { isLoading: isLoadingMortgageInsurance }
  ] = useMutation(updateLedgerEntries, {
    onError: () => { showAlert(capitalize(t('dialogs:there-is-an')), 'error'); }
  });

  return {
    getProjectedPayments,
    methods,
    updateProjectedPayments,
    updateOrderProjectedResponse,
    orderId,
    estimatedEscrowEntry: getProjectedPayments?.data?.getOrder.accounting.find(f => f.code.includes('estimated_escrow')),
    mortgageInsuranceEntry: getProjectedPayments?.data?.getOrder.accounting.find(f => f.code.includes('mortgage_insurance')),
    currentEntry,
    setCurrentEntry,
    referenceEstimatedValue,
    referenceMortgageValue,
    isLoadingMortgageInsurance,
    updateAccountingEntries
  };
};

export default useLoanTerms;
