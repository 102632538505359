import { gql } from "graphql-request";
import gqlRequest from "graphql/generic";
import { projectedPayments } from ".";
import { AccountingFilter, AccountingType } from "v2-types/order";

const requestString = gql`
  query Projected_payments($id: ID!,$filters: OrderFilters) {
  getOrder(_id: $id,filters: $filters) {
    projected_payments {
      year_ranges
      principal_kind
      principal_amount
      principal_min
      principal_max
      interest_only
      prorations_amount
      prorations_period
      includes_taxes
      includes_homeowners
      includes_other
      other_description
      escrow_homeowners
      escrow_taxes
      escrow_other
    }
    accounting {
      _id
      amount
      amount_calculated
      amount_override
      code
      kind
      letter
      number
      entry_date
      description
      per_month
      months_advanced
      annuality
      months
    }
  }
}
`;

type GetProjectedPaymentsReturn = {
  getOrder: {
    projected_payments:projectedPayments,
    accounting: Pick<AccountingType,
    '_id' | 'code' | 'description' | 'kind' | 'letter' | 'amount' | 'per_month' | 'number' | 'amount_override'|'months_advanced'| 'annuality' | 'months' | 'amount_calculated'>[]
  };
};

type GetProjectedPaymentsParams = {
  id: string,
  filters?: {
    [key: string]: AccountingFilter
  }
};

export const getOrderProjectedPayments = (id: string, filters?:any) => gqlRequest<GetProjectedPaymentsReturn, GetProjectedPaymentsParams>(
  requestString, { id, filters });

export default {};
