import { useForm } from "react-hook-form";
import { useYupValidationResolver } from 'utils/ledger/use-yup-resolver';
import { formDocumentCalculation } from "./services";
import useAlert from "utils/alert";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as yup from 'yup';
import { useMutation } from "react-query";
import { addManualCharge } from "components/add-entry-modal/services/mutations";
import { capitalize } from "utils/helpers";
import { AccountingType } from "v2-types/order";
import { useState } from "react";

export const validationSchema = yup.object({
  code: yup.string().required('Required'),
  description: yup.string().required('Required'),
  kind: yup.string().required('Required'),
});

const useDocumentFeesModal = (
  onClose:()=> void,
  onSuccesAddEntry: (accounting: AccountingType[]) => void,
  letter:string
) => {
  const { id: orderId } = useParams<{ id: string }>();
  const [createErrors, setcreateErrors] = useState<string[]>([]);
  const showAlert = useAlert();
  const { t } = useTranslation();
  const resolver = useYupValidationResolver(validationSchema);
  const methods = useForm<formDocumentCalculation>({
    defaultValues: {
      numberOfNames: 0,
      numberOfPages: 0,
      code: 'document',
      kind: 'manual',
      letter,
      description: ''
    },
    resolver
  });

  const [AddManualcharge, responseAddManualcharge] = useMutation(addManualCharge, {
    onSuccess: (data) => {
      if (data) {
        showAlert(capitalize(t('dialogs:order-updated')), 'success');
        onSuccesAddEntry(data.createOrderLedgerEntry.accounting || []);
        responseAddManualcharge.reset();
        methods.reset();
        onClose();
      }
    },
    onError: (e: any) => {
      const { errors = ['Error Adding Association'] } = e?.response || {};
      const errorsMapped = errors.map((error: any) => error.message);
      setcreateErrors(errorsMapped);
    },
  });
  const handleEntry = methods.handleSubmit((data) => {
    AddManualcharge({
      id: orderId!,
      description: data.description,
      code: data.code,
      kind: data.kind,
      number: null,
      amount: parseFloat(data.amount as unknown as string) || 0,
      months: null,
      entryDate: null,
      letter: data.letter === '' ? null : data.letter,
      months_advanced: null,
      annuality: null,
      filters: undefined,
    });
  });
  return {
    methods,
    handleEntry,
    responseAddManualcharge,
    createErrors
  };
};

export default useDocumentFeesModal;