/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import useAlert from 'utils/alert';
import { capitalize } from 'utils/helpers';
import useDeleteLedger from 'utils/ledger/use-delete-ledger-entry';
import { AccountingType } from 'v2-types/order';

import { initialState, reducerModals } from '../components/prototype-new-table-charges/services';
import { columnsDataSet } from '../components/table-charges-kmln/services';
import { getOrder } from './querys';

const useChargesKMLN = (allowedLetters:string[]) => {
  const { id: orderId } = useParams<{ id: string }>();
  const [accounting, setAccounting] = useState<AccountingType[]>([]);
  const [state, dispatch] = useReducer(reducerModals, initialState);
  const [itemSelected, setSelectItem] = useState<columnsDataSet | null>(null);
  const showAlert = useAlert();
  const { t } = useTranslation();
  const getOrderAccounting = useQuery(['accounting-order', orderId],
	  () => getOrder(orderId),
	  {
      enabled: !!orderId,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data) {
          if (Array.isArray(allowedLetters)) {
            setAccounting(data.getOrder?.accounting?.filter((f) => allowedLetters.some((g) => f.letter?.includes(g))) || []);
          }
        }
      }
	  },);

  const { deleteEntry, deleteEntryResponse } = useDeleteLedger((data) => {
	  deleteEntryResponse.reset();
	  showAlert(capitalize(t('dialogs:order-updated')), 'success');
	  setAccounting(data.deleteOrderLedgerEntry?.accounting?.filter((f) => allowedLetters.some((g) => f.letter?.includes(g)) || []) || []);
  },
  () => { showAlert('there was an error deleting the entry', 'error'); }
  );

  const handleDelete = useCallback((item:columnsDataSet) => {
	  deleteEntry({
      id: orderId,
      accountingId: item.id
	  });
  }, [deleteEntry, orderId]);

  const handleEditModal = (item:columnsDataSet) => {
    dispatch({ type: 'edit', data: item });
  };

  const handlePayor = (item:columnsDataSet) => {
    dispatch({ type: 'parties', data: item });
  };

  const handleUpdateAccounting = (acc:AccountingType[]) => {
    setAccounting(acc);
  };

  const handleSelectItem = (item:columnsDataSet) => {
    setSelectItem(item);
  };

  const handleAddModal = (type:string | null) => {
    dispatch({ type });
  };

  useEffect(() => {
	  if (getOrderAccounting.isLoading || getOrderAccounting.isFetching) {
      showAlert('Updating...', 'info');
	  }
  }, [getOrderAccounting.isLoading, getOrderAccounting.isFetching]);

  useEffect(() => {
	  if (deleteEntryResponse.isLoading) {
      showAlert('Deleting Entry...', 'info');
	  }
  }, [deleteEntryResponse.isLoading]);

  const openAddModal = state.type === 'add' || state.type === 'edit';

  return {
	  dataCharges: accounting,
	  loading: getOrderAccounting.isLoading,
	  setAccounting,
	  openAddModal,
	  itemSelected,
	  handleEditModal,
	  handlePayor,
	  handleDelete,
	  handleUpdateAccounting,
	  handleAddModal,
	  handleSelectItem,
	  state
  };
};

export default useChargesKMLN;
