import { LOCAL_STORAGE_TABS_KEY } from "utils/constants";
import { TabOrder } from "..";

export const WORKSPACE_DETAILS_ROUTE = "/orders/details";

export const getTabsFromLocalStorage = async () => {
  let tabs: TabOrder[] = [];
  try {
    const stringTabs = window.localStorage.getItem(LOCAL_STORAGE_TABS_KEY);
    if (stringTabs) {
      tabs = await JSON.parse(stringTabs);
    }
  } catch (error) {
    return [];
  }
  return tabs;
};