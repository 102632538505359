import { makeStyles, MenuItem, TextField } from '@material-ui/core';
import React from 'react';
import { formatCurrencyValueToNumber } from 'utils/helpers';

import InputCell from './input-cell';
import { Controller, UseFormReturn } from 'react-hook-form';
import { ProjectedPaymentsForm } from '../services';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    '&>*': { marginRight: theme.spacing(4), },
  },
  textField: {
    height: theme.spacing(4),
    '&>.MuiInputBase-root': {
      height: theme.spacing(4),
      '&>.MuiSelect-root': {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
      },
    },
  },
}));

type PrincipalInterestAmountFieldsProps = {
  methods: UseFormReturn<ProjectedPaymentsForm, any, undefined>
};

const AmountFields = ({
  methods
}: PrincipalInterestAmountFieldsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const data =
    [
      {
        name: 'month',
        text: t('projectedPayments:per-month')
      },
      {
        name: 'week',
        text: t('projectedPayments:per-week')
      },
    ];

  return (
    <span className={classes.root}>
      <Controller
        control={methods.control}
        name="prorations_amount"
        render={({ field }) => (
          <InputCell
            value={field.value}
            prefix="$"
            thousandSeparator
            onChangeInput={(e: any) => {
              const value = formatCurrencyValueToNumber(e.target.value);
              field.onChange(value);
            }}
          />
        )}
      />

      <Controller
        control={methods.control}
        name="prorations_period"
        render={({ field }) => (
          <TextField
            select
            fullWidth
            variant="filled"
            value={field.value}
            className={classes.textField}
            InputProps={{ disableUnderline: true }}
            onChange={(e) => field.onChange(e.target.value)}
          >
            {data.map((element: any) => (
              <MenuItem
                key={element.name}
                value={element.name}
              >
                {element.text}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
    </span>
  );
};

export default AmountFields;
