import {
  Box,
  Grid,
  makeStyles,
  Paper,
} from '@material-ui/core';
import AddLedgerEntry from 'components/add-entry-modal/add-entry-modal';
import { ContainedButton } from 'components/ContainedButton';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountingType } from 'v2-types/order';

import Header from '../components/header';
import PayorInfo from '../components/payor-info';
import { columnsDataSet } from '../components/table-charges-kmln/services';
import TablePrototypeKdues from '../components/table-charges-kmln/table-prototype-kdues';
import { chargesSorted, getDisabledFieldsv2, getHideFieldsv2 } from '../services';
import { mockData } from './services';

const useStyles = makeStyles((theme) => ({
  padding: { padding: theme.spacing(3) },
  marginCard: { marginBottom: theme.spacing(2) }
}));

type ChargesKMSCeneProps = {
  accountingData: AccountingType[],
  handleUpdateAccounting: (acc: AccountingType[]) => void,
  handleEditModal: (item: columnsDataSet) => void,
  handlePayor: (item: columnsDataSet) => void,
  handleDelete: (item: columnsDataSet) => void,
  openAddModal: boolean,
  handleaddModal: (modal: string | null) => void,
  state: { type: string; data?: any; }
}

const ChargesKMScene: FC<ChargesKMSCeneProps> = ({
  accountingData,
  handleUpdateAccounting,
  handleEditModal,
  handleDelete,
  handlePayor,
  openAddModal,
  handleaddModal,
  state
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const currentChargeData = accountingData.find((acc) => acc._id === state.data?.chargeData?._id);
  const mapedData = useMemo(() => mockData(chargesSorted(accountingData, true)), [accountingData]);
  return (
    <>
      <Grid container direction="column" className={classes.padding}>
        <Grid item xs={12}>
          <Paper>
            <Header
              title={t('charges:debits-and-credits')}
              chargesIdentifier="K-M"
            />
            <Grid container direction="row">
              <Grid item xs={6}>
                <Box display="flex" flexDirection="column">
                  <TablePrototypeKdues
                    data={mapedData.kdues}
                    handleEditModal={handleEditModal}
                    handleDelete={handleDelete}
                    handlePayor={handlePayor}
                    title={t('charges:k-due-borrower')}
                    type="dues"
                  />
                  <TablePrototypeKdues
                    data={mapedData.kAdjustments}
                    handleEditModal={handleEditModal}
                    handleDelete={handleDelete}
                    handlePayor={handlePayor}
                    title={t('charges:adjustments')}
                    type="adjustments"
                  />
                  <TablePrototypeKdues
                    data={mapedData.kAdjustmentsItems}
                    handleEditModal={handleEditModal}
                    handleDelete={handleDelete}
                    handlePayor={handlePayor}
                    title={t('charges:adjustments-for-items')}
                    type="adjustments"
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box display="flex" flexDirection="column">
                  <TablePrototypeKdues
                    data={mapedData.ldues}
                    handleEditModal={handleEditModal}
                    handleDelete={handleDelete}
                    handlePayor={handlePayor}
                    title={t('charges:m-due-seller')}
                    type="dues"
                  />
                  <TablePrototypeKdues
                    data={mapedData.ladjustments}
                    handleEditModal={handleEditModal}
                    handleDelete={handleDelete}
                    handlePayor={handlePayor}
                    title={t('charges:adjustments-for-items')}
                    type="adjustments"
                  />
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {(mapedData.kaddendums.length > 0 || mapedData.laddendums.length > 0)
          && (
            <Grid item xs={12} className={classes.marginCard}>
              <Paper>
                <Grid container direction="row">
                  <Grid item xs={6}>
                    <TablePrototypeKdues
                      data={mapedData.kaddendums}
                      handleEditModal={handleEditModal}
                      handleDelete={handleDelete}
                      handlePayor={handlePayor}
                      title="Addendums K"
                      type="addendums"
                    />

                  </Grid>
                  <Grid item xs={6}>
                    <TablePrototypeKdues
                      data={mapedData.laddendums}
                      handleEditModal={handleEditModal}
                      handleDelete={handleDelete}
                      handlePayor={handlePayor}
                      title="Addendums M"
                      type="addendums"
                    />

                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )}
        <Grid item xs={12} className={classes.marginCard}>
          <ContainedButton
            icon="plus"
            text={t('charges:addendum')}
            onClick={() => {
              handleaddModal('add');
            }}
          />
        </Grid>

        {state.type === 'parties' && currentChargeData && (
          <Grid item xs={12}>
            <PayorInfo
              handleSuccess={handleUpdateAccounting}
              chargeData={currentChargeData}
            />
          </Grid>
        )}
      </Grid>

      {openAddModal && (
        <AddLedgerEntry
          open={!!openAddModal}
          handleClose={() => { handleaddModal(null); }}
          onSuccesAddEntry={handleUpdateAccounting}
          prevData={state.type === 'edit' ? state.data?.chargeData! : null}
          kindAllowedEntry={['manual']}
          codeAllowedEntry={['charge']}
          lettersAllowedEntry={["k", "m"]}
          disableFields={getDisabledFieldsv2(state)}
          hideFields={getHideFieldsv2(state)}
          isEditingEntry={state.type === 'edit' && state.data?.chargeData?._id.length > 10}
        />
      )}
    </>
  );
};

export default ChargesKMScene;
