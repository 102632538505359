import useAlert from 'utils/alert';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { getOrder } from '../services/querys';
import { useState } from 'react';
import { AccountingType } from 'v2-types/order';
import useDeleteEntry from 'utils/ledger/use-delete-ledger-entry';
import { createLedgerLink, mutationEditEntry } from '../services/mutations';
import { filterEntriesToDisbursment } from '../services';
import { createEntryForm, editEntryForm } from '../types';
import moment from 'moment';

type ConfirmDelateType = {
  openConfirm: boolean;
  callbackSuccess: (() => void) | null;
  title: string;
  confirmationMessage: string;
};

type ModalsType = {
  kind: string;
  idEntry?: string;
};

const useDisbursments = (kindEntry: string[]) => {
  const showAlert = useAlert();
  const { t } = useTranslation();
  const { id: orderId } = useParams<{ id: string }>();
  const [accounting, setAccounting] = useState<AccountingType[]>([]);
  const [disbursments, setDisbursments] = useState<AccountingType[]>([]);
  const [modals, setModals] = useState<ModalsType>({
    kind: '',
    idEntry: '',
  });
  const [confirmDelate, setConfirmDelate] = useState<ConfirmDelateType>({
    openConfirm: false,
    callbackSuccess: null,
    title: '',
    confirmationMessage: '',
  });

  const updateDisbursments = (entries: AccountingType[]) => {
    const { filteredDisbursments, accountingFiltered } = filterEntriesToDisbursment(
      entries,
      kindEntry
    );
    setAccounting(accountingFiltered);
    setDisbursments(filteredDisbursments);
  };

  const { isLoading, isFetching } = useQuery(
    ['accounting-order-disbursments', orderId],
    () => getOrder(orderId),
    {
      enabled: !!orderId,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data) {
          updateDisbursments(data.getOrder.accounting);
        }
      },
    }
  );

  const handleModals = (modalName: string, idEntry?: string) => {
    setModals({
      kind: modalName,
      idEntry,
    });
  };

  const { deleteEntry, deleteEntryResponse } = useDeleteEntry(
    (data) => {
      if (data) {
        updateDisbursments(data.deleteOrderLedgerEntry.accounting ?? []);
        showAlert(t('dialogs:order-updated'), 'success');
        deleteEntryResponse.reset();
      }
    },
    () => {
      showAlert(t('dialogs:there-is-an'), 'error');
    }
  );

  const handleDeleteEntry = (accountingId: string) => {
    deleteEntry({
      id: orderId,
      accountingId,
    });
  };

  const [createLedgerLinkMutation, createLedgerLinkResponse] = useMutation(createLedgerLink, {
    onSuccess: (data) => {
      if (data) {
        updateDisbursments(data.helpOrderLinkedEntry.accounting ?? []);
        showAlert(t('dialogs:order-updated'), 'success');
        createLedgerLinkResponse.reset();
      }
    },
    onError: () => {
      showAlert(t('dialogs:there-is-an'), 'error');
    },
  });

  const [editLedgerEntryMutation, editLedgerEntryResponse] = useMutation(mutationEditEntry, {
    onSuccess: (data) => {
      if (data) {
        updateDisbursments(data.updateOrderLedgerEntry.accounting ?? []);
        showAlert(t('dialogs:order-updated'), 'success');
        editLedgerEntryResponse.reset();
      }
    },
    onError: () => {
      showAlert(t('dialogs:there-is-an'), 'error');
    },
  });

  const handleConfirmDelete = (
    openConfirm: boolean,
    callback: (() => void) | null,
    title: string,
    confirmationMessage: string
  ) => {
    setConfirmDelate({
      openConfirm,
      title,
      confirmationMessage,
      callbackSuccess: () => {
        if (callback) {
          callback();
          handleConfirmDelete(false, null, '', '');
        }
      },
    });
  };

  const handleCreationEntry = (data: createEntryForm) => {
    const filteredTargets = data.targets.filter((target) => target.checked);

    const targetsWithoutChecked = filteredTargets.map(({ checked, ...target }) => target);

    const dataCreationEntry = {
      ...data,
      entryDate: moment(data.entryDate).format('YYYY-MM-DD'),
      targets: targetsWithoutChecked,
      id: orderId,
    };

    createLedgerLinkMutation(dataCreationEntry);
  };

  const handleEditEntry = (data: editEntryForm, id: string) => {
    const dataEditEntry = {
      id: orderId,
      accountingId: id,
      data: {
        ...data,
        entry_date: moment(data.entry_date).format('YYYY-MM-DD'),
      },
    };

    editLedgerEntryMutation(dataEditEntry);
  };

  return {
    disbursments,
    isLoading: isLoading || isFetching,
    handleModals,
    modals,
    handleDeleteEntry,
    accounting,
    confirmDelate,
    handleConfirmDelete,
    handleCreationEntry,
    handleEditEntry,
    isLoadingMutations: createLedgerLinkResponse.isLoading || deleteEntryResponse.isLoading || editLedgerEntryResponse.isLoading,
  };
};

export default useDisbursments;
