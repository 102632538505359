import { Grid, Paper } from '@material-ui/core';
import React, { useState } from 'react';
import Header from '../components/header';
import { useTranslation } from 'react-i18next';
import DocumentsDetails from './components/document-details-container';
import ChargesContainer from '../components/chargeContainer';
import { ChargesTable } from '../components/ChargesTable';

const ChargesE = () => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const handleIsLoading = (value) => {
    setIsLoading(value);
  };

  return (
    <ChargesContainer chargesLetter="E">
      <Grid container direction="column">
        <Grid item xs={12} style={{ marginBottom: 24 }}>
          <Paper>
            <Header
              title={t('charges:taxes-and-goverment-fees')}
              t={t}
            />

            <DocumentsDetails handleIsLoading={handleIsLoading} />
            <ChargesTable
              isLoadingInfo={isLoading}
              accountingFilter={(acc) => acc.filter((charge) => charge.number !== null)}
            />
          </Paper>
        </Grid>
      </Grid>
    </ChargesContainer>
  );
};

export default ChargesE;
