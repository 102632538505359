import { AccountingInvolvedParty, AccountingType } from 'v2-types/order';
import { AccountingCharge } from '../types';
import { formatMoney } from 'utils/helpers';

export const formatOThersText = (parties: AccountingInvolvedParty[]) =>
  parties
    .filter(
      (party) =>
        !party.order_kinds.includes('Buyer') &&
        !party.order_kinds.includes('Seller') &&
        party.kind.includes('Payer')
    )
    .map((party) => `${party.name}:${formatMoney(party.amount)}`)
    .join('-');

export const formatAccountingData = (accountingData: AccountingType[]): AccountingCharge[] =>
  accountingData.map((charge) => ({
    accounting_id: charge._id,
    description: charge.description,
    kind: charge.kind,
    number: Number(charge.number),
    months: charge.months_advanced || 0,
    per_month: charge.per_month || 0,
    tax: charge.involved_parties
      .filter((party) => party.kind.includes('Tax'))
      .map((data) => data.amount)
      .reduce((partialSum, a) => partialSum + a, 0),
    payee: charge.involved_parties
      .filter((party) => party.kind.includes('Payee'))
      .map((p) => p.name)
      .join(','),
    payee_id: charge.involved_parties
      .filter((party) => party.kind.includes('Payee'))
      .map((p) => p._id)
      .join(','),
    seller_before:
      charge.involved_parties.reduce((prev, curr) => {
        if (curr.order_kinds.includes('Seller') && curr.kind.includes('Payer')) {
          return prev + curr.before_closing_amount;
        }
        return prev;
      }, 0) || 0,
    seller_at:
      charge.involved_parties.reduce((prev, curr) => {
        if (curr.order_kinds.includes('Seller') && curr.kind.includes('Payer')) {
          return prev + curr.at_closing_amount;
        }
        return prev;
      }, 0) || 0,
    buyer_before:
      charge.involved_parties.reduce((prev, curr) => {
        if (curr.order_kinds.includes('Buyer') && curr.kind.includes('Payer')) {
          return prev + curr.before_closing_amount;
        }
        return prev;
      }, 0) || 0,
    buyer_at:
      charge.involved_parties.reduce((prev, curr) => {
        if (curr.order_kinds.includes('Buyer') && curr.kind.includes('Payer')) {
          return prev + curr.at_closing_amount;
        }
        return prev;
      }, 0) || 0,
    by_others:
      charge.involved_parties.reduce((prev, curr) => {
        if (
          !curr.order_kinds.includes('Buyer') &&
          !curr.order_kinds.includes('Seller') &&
          curr.kind.includes('Payer')
        ) {
          return prev + curr.amount;
        }
        return prev;
      }, 0) || 0,
    letter: charge.letter || '',
    amount_override: charge.amount_override,
    amount_calculated: charge.amount_calculated
  }));

export const getChargeTitleTooltip = (charge: AccountingCharge) => {
  const amount = formatMoney(charge.amount_calculated || 0);
  return `Overriden \n Originally ${amount}`;
};
