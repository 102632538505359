import {
  Box,
  Button,
  Dialog,
  DialogContent,
  makeStyles,
  MenuItem,
  TextField,
  Typography
} from '@material-ui/core';
import { BaseButton } from 'components/BaseButton';
import { SearchPartyInput } from 'components/search-party-input';
import { getLedgerListings } from 'graphql/ledger/queries';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import SearchContact from 'views/orders/new-charges/components/searchContact';

const useStyles = makeStyles((theme: any) => ({
  input: {
    '&:focus': { backgroundColor: theme.palette.background.default },
    '& .MuiFilledInput-root': {
      textTransform: 'none',
      fontSize: '0.875rem',
      color: 'black',
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      backgroundColor: theme.palette.background.default,
    },
  },
  dialog: { padding: theme.spacing(4) },
  modaltitle: {
    fontWeight: 500,
    color: theme.palette.common.black,
    marginBottom: theme.spacing(2)
  },
  modalText: {
    color: theme.palette.common.black,
    marginBottom: theme.spacing(2)
  },
  button: {
    textTransform: 'none',
    fontWeight: 500,
    marginLeft: theme.spacing(2)
  },
  textbutton: { fontWeight: 500, color: theme.palette.common.black },
  containerItems: {
    marginBottom: theme.spacing(3),
    width: "100%"
  },
  containerSelectedUser: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3)
  }
}));

type AddcontactModalProps = {
  open: boolean;
  onClose: () => void;
  onClickAddParty: (partyId: string, kind: string, callback: () => void) => void;
  allowedCodeAssociations: string,
  loading?: boolean,
  preselectedcontact?: {
    _id: string,
    name: string
  },
}

const AddContactModal: React.FC<AddcontactModalProps> = ({
  open,
  onClose,
  onClickAddParty,
  loading,
  allowedCodeAssociations,
  preselectedcontact
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [currentUserSelected, setCurrentUserSelected] = useState<{ _id: string, name: string } | null>(preselectedcontact || null);
  const [currentAssociation, setCurrentAssociation] = useState<string | null>(null);
  const getLedgerAllowedAssociations = useQuery(
    ['listings', allowedCodeAssociations],
    () => getLedgerListings(allowedCodeAssociations),
    {
      enabled: open,
      refetchOnWindowFocus: false
    }
  );

  const onCloseModal = () => {
    setCurrentUserSelected(null);
    setCurrentAssociation(null);
    onClose();
  };
  return (
    <Dialog open={open} onClose={onCloseModal} fullWidth maxWidth="md">
      <DialogContent className={classes.dialog}>
        <Typography variant="body1" className={classes.modaltitle}>
          {t('parties:search-through')}
        </Typography>
        <Typography variant="body2" className={classes.modalText}>
          {t('parties:search-through-text')}
        </Typography>
        <Box className={classes.containerItems}>
          {preselectedcontact ?
            <Box>
              <Typography>Selected Contact:</Typography>
              <Typography>{preselectedcontact.name}</Typography>
            </Box>
            :
            <SearchContact
              getValue={(contact) => setCurrentUserSelected({ _id: contact?._id!, name: contact?.name! })}
              reference={null}
              initialValue={{ name: '', _id: '' }}
            />}
        </Box>
        <Box display="flex" flex={1} className={classes.containerItems}>
          <TextField
            select
            label="Role"
            fullWidth
            value={currentAssociation || ''}
            variant="filled"
            className={classes.input}
            onChange={(e) => {
              setCurrentAssociation(e.target.value);
            }}
          >
            <MenuItem value="">
              {t('common:select-one')}
            </MenuItem>
            {getLedgerAllowedAssociations.data?.getListing.entries.map((f) => f.description).map((j) => (
              <MenuItem key={j} value={j}>
                {j}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box
          display="flex"
          flex="1"
          flexDirection="row"
          justifyContent="flex-end"
          className={classes.containerItems}
        >
          <Button className={classes.button} onClick={onCloseModal}>
            {t('parties:cancel')}
          </Button>
          <BaseButton
            disableElevation
            isLoading={loading}
            disabled={currentUserSelected === null || currentAssociation === null}
            text={t('parties:add-party')}
            onClick={() => {
              if (currentUserSelected && currentAssociation) {
                onClickAddParty(currentUserSelected._id, currentAssociation, onCloseModal);
              }
            }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddContactModal;
