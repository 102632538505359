import {
  Button,
  ButtonGroup,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import NumberField from 'components/number-field';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AccountingType } from 'v2-types/order';
import { usePolicyInfoContext } from '../contexts/policyInfoContext';

import {
  getAmount,
  LENDERS_ACTUAL,
  LENDERS_DISCLOSED,
  LENDERS_POLICY,
  OWNERS_ACTUAL,
  OWNERS_DISCLOSED,
  OWNERS_POLICY
} from '../services';
import { FormPolicy } from '../services/types';
import PaymentBreakDown from './premium-breakdown';

const useStyles = makeStyles((theme: any) => ({
  amountsContainer: { marginTop: theme.spacing(2) },
  disclosureContainer: { marginTop: theme.spacing(3) },
  optionsContainer: { marginTop: theme.spacing(4) },
  title: { color: theme.palette.tab.offselected },
  caption: {
    marginBottom: theme.spacing(0.2),
    color: theme.palette.primary.darker,
    fontSize: 10,
    textTransform: 'uppercase',
  },
  currency: {
    color: theme.palette.primary.darker,
    fontWeight: 'bold',
  },
  button: { textTransform: 'capitalize' },
}));

const FormPremiums = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { accounting, selectedPolicy } = usePolicyInfoContext();
  const { setValue, watch } = useFormContext<FormPolicy>();
  const memoizedPremium = useMemo<Map<string, {
    actual: AccountingType | undefined,
    disclosed: AccountingType | undefined,
    policy: AccountingType | undefined
  }>>(() => {
    const mapBalances = new Map();
    const ownersData = {
      actual: accounting?.find((acc) => acc.code.includes(OWNERS_ACTUAL)),
      disclosed: accounting?.find((acc) => acc.code.includes(OWNERS_DISCLOSED)),
      policy: accounting?.find((acc) => acc.code.includes(OWNERS_POLICY))
    };
    const lendersData = {
      actual: accounting?.find((acc) => acc.code.includes(LENDERS_ACTUAL)),
      disclosed: accounting?.find((acc) => acc.code.includes(LENDERS_DISCLOSED)),
      policy: accounting?.find((acc) => acc.code.includes(LENDERS_POLICY))
    };
    mapBalances.set('owners_policy', ownersData);
    mapBalances.set('lenders_policy', lendersData);
    return mapBalances;
  }, [accounting]);
  const disclosureLendersSection = watch(`${selectedPolicy}.disclosure_section`);

  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <Grid container direction="row">
          <Grid item>
            <Typography variant="h6" className={classes.title}>
              {t('policyInfoAndRates:premiums')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container direction="row">
          <Grid item xs={6} className={classes.amountsContainer}>
            <Grid container direction="column">
              <Grid item xs={12}>
                <Typography className={classes.caption}>
                  {t('policyInfoAndRates:actual')}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <NumberField
                  naked
                  isTitle
                  disabled
                  thousandSeparator
                  decimalScale={2}
                  prefix="$"
                  value={getAmount(memoizedPremium.get(selectedPolicy)?.actual)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6} className={classes.amountsContainer}>
            <Grid container direction="column">
              <Grid item xs={12}>
                <Typography className={classes.caption}>
                  {t('policyInfoAndRates:disclosed')}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <NumberField
                  naked
                  isTitle
                  disabled
                  thousandSeparator
                  decimalScale={2}
                  prefix="$"
                  value={getAmount(memoizedPremium.get(selectedPolicy)?.disclosed)}
                />
              </Grid>
            </Grid>
          </Grid>

        </Grid>
        {selectedPolicy === 'lenders_policy'
          && (
            <Grid container direction="row">
              <Grid item xs={12} className={classes.disclosureContainer}>
                <Grid container direction="column">
                  <Grid item xs={12}>
                    <Typography className={classes.caption}>
                      {t('policyInfoAndRates:disclosure-section')}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <ButtonGroup disableElevation color="primary" size="small">
                      <Button
                        className={classes.button}
                        variant={disclosureLendersSection === 'B' ? 'contained' : 'outlined'}
                        onClick={() => { setValue(`${selectedPolicy}.disclosure_section`, 'B', { shouldDirty: true }); }}
                      >
                        B
                      </Button>

                      <Button
                        className={classes.button}
                        variant={disclosureLendersSection === 'C' ? 'contained' : 'outlined'}
                        onClick={() => { setValue(`${selectedPolicy}.disclosure_section`, 'C', { shouldDirty: true }); }}
                      >
                        C
                      </Button>
                    </ButtonGroup>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
      </Grid>
      <PaymentBreakDown
        accountingInfo={memoizedPremium.get(selectedPolicy)?.policy}
        direction="row"
      />
    </Grid>
  );
};

export default FormPremiums;
