import { TableCell, Typography } from '@material-ui/core';
import React from 'react';
import { formatMoney } from 'utils/helpers';

const CellBalance = ({ balance, className, text }) => {
  return (
    <>
      <TableCell>
        <Typography className={className}>{text}</Typography>
      </TableCell>
      <TableCell>
        <Typography className={className}>{formatMoney(balance)}</Typography>
      </TableCell>
    </>
  );
};

export default CellBalance;
