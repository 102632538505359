import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

import { getEstimatedTaxesRows, ProjectedPaymentsForm } from '../services';
import ProjectedPaymentsTable from './projected-payments-table';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: any) => ({
  subTitle: {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
}));

type EstimatedTaxesSectionTableType = {
  methods: UseFormReturn<ProjectedPaymentsForm, any, undefined>
};

const EstimatedTaxesSectionTable = ({
  methods,
}: EstimatedTaxesSectionTableType) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const fields = [
    'amount',
    'includes',
    'inEscrow'
  ];
  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <Typography variant="body2" className={classes.subTitle}>
          {t('projectedPayments:estimated-taxes-title')}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <ProjectedPaymentsTable
          data={fields}
          rows={getEstimatedTaxesRows({ t, methods })}
        />
      </Grid>
    </Grid>
  );
};

export default EstimatedTaxesSectionTable;
