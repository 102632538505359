import React, { Children, FC } from 'react';

type SimpleCellTextProps = {
  textAlign?: 'center' | 'left' | 'right',
  children: any
}

const SimpleCellText: FC<SimpleCellTextProps> = ({
  textAlign,
  children
}) => {
  return (
    <div style={{ textAlign, fontSize: 13 }}>
      {children as any}
    </div>
  );
};

export default SimpleCellText;
