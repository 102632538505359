import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { deleteCharges, updateCharges } from '../services/mutations';
import useAlert from 'utils/alert';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { formatAccountingData } from '../services';
import { AccountingCharge } from '../types';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchemaCharges } from '../services/validate-entry';
import { useForm } from 'react-hook-form';
import { AccountingType } from 'v2-types/order';

type anchorElRowType = {
  anchorEl: string;
  accountingId: string;
  number: number;
};

type Params = {
  accountingData: AccountingType[],
  currentLetter: string,
  filterCharges: (data: AccountingType[]) => AccountingType[]
}

const useTableCharges = ({
  accountingData,
  currentLetter,
  filterCharges
}: Params) => {
  const [formatedAccountingData, setFormatedAccountingData] = useState(
    formatAccountingData(accountingData) || []
  );
  const showAlert = useAlert();
  const { t } = useTranslation();
  const { id: orderId } = useParams<{ id: string }>();
  const [anchorElRow, setAnchorElRow] = useState<anchorElRowType | null>(null);

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { isValid, isDirty },
  } = useForm({
    defaultValues: {
      charges: formatedAccountingData,
    },
    resolver: yupResolver(validationSchemaCharges),
    mode: 'onChange',
  });

  const handleChangeMonth = (charge: AccountingCharge, idx: number) => {
    const perMonth = charge.per_month ?? 0;
    const months = charge.months ?? 0;
    const newCharge = {
      ...charge,
      tax: 0,
      seller_before: 0,
      seller_at: 0,
      buyer_before: 0,
      buyer_at: perMonth * months,
      by_others: 0,
    };
    const currentFormValues = getValues().charges;

    currentFormValues[idx] = newCharge;
    setValue("charges", currentFormValues, { shouldDirty: true });
  };

  const handleCloseDialog = () => {
    setAnchorElRow(null);
  };

  const handleClickMenu = (event, accountingId, number) => {
    setAnchorElRow({
      anchorEl: event.currentTarget,
      accountingId,
      number,
    });
  };

  useEffect(() => {
    setFormatedAccountingData(
      formatAccountingData(accountingData).sort(
        (chargeA, chargeB) => chargeA.number - chargeB.number
      ) || []
    );
  }, [accountingData]);

  const resetIndexCharges = (charges: AccountingCharge[]) => {
    return charges.map((charge, idx) => ({ ...charge, number: idx + 1 }));
  };

  const updateChargesState = (charges: AccountingCharge[], shouldDirtyForm?: boolean) => {
    const newCharges = resetIndexCharges(charges);
    setFormatedAccountingData(newCharges);
    setValue("charges", newCharges, { shouldDirty: shouldDirtyForm ?? true });
  };

  const [setUpdateCharges, updateChargesResponse] = useMutation(updateCharges, {
    onSuccess: (data) => {
      if (data) {
        showAlert(t('charges:success-updated'), 'success');
        const chargesFiltered = filterCharges(data.helpOrderCharges.accounting ?? []);
        updateChargesState(formatAccountingData(chargesFiltered), false);
      }
    },
    onError: () => {
      showAlert(t('charges:error-updated'), 'error');
    },
  });

  const [setDeleteCharges, deleteChargesResponse] = useMutation(deleteCharges, {
    onSuccess: (data) => {
      if (data) {
        const { charges } = getValues();
        const newData = charges.filter((charge) => charge.accounting_id !== anchorElRow?.accountingId);
        updateChargesState(newData);

        setAnchorElRow(null);
        showAlert(t('charges:success-deleted'), 'success');
      }
    },
    onError: () => {
      showAlert(t('charges:error-deleted'), 'error');
    },
  });

  const handleDeleteCharge = (accountingId, number) => {
    if (accountingId) {
      setDeleteCharges({
        id: orderId,
        accountingId,
      });
    } else {
      const { charges } = getValues();
      const newData = charges.filter((charge) => charge.number !== number);
      updateChargesState(newData);

      setAnchorElRow(null);
    }
  };

  const hanldeUpdateCharge = (dataCharges) => {
    setUpdateCharges({
      id: orderId,
      data: dataCharges,
      filters: {
        accounting: currentLetter,
      },
    });
  };

  const hanldeAddEmptyCharges = () => {
    const { charges } = getValues();
    const chargesLength = charges.length - 1;

    const emptyCharge: AccountingCharge = {
      accounting_id: null,
      description: '',
      kind: 'manual',
      number:
        (formatedAccountingData[chargesLength] !== undefined
          ? formatedAccountingData[chargesLength].number
          : 0) + 1,
      months: 0,
      per_month: 0,
      tax: 0,
      payee: '',
      payee_id: '',
      seller_before: 0,
      seller_at: 0,
      buyer_before: 0,
      buyer_at: 0,
      by_others: 0,
      letter: currentLetter,
      amount_override: 0,
      amount_calculated: 0
    };

    updateChargesState([...charges, emptyCharge]);
  };

  return {
    hanldeUpdateCharge,
    isLoading: updateChargesResponse.isLoading || deleteChargesResponse.isLoading,
    t,
    anchorElRow,
    handleClickMenu,
    handleCloseDialog,
    handleDeleteCharge,
    formatedAccountingData,
    hanldeAddEmptyCharges,
    control,
    getValues,
    handleSubmit,
    isValid,
    handleChangeMonth,
    isFormDirty: isDirty
  };
};

export default useTableCharges;
