import { makeStyles, TableCell, Typography } from '@material-ui/core';
import Switch from 'components/switch';
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { ProjectedPaymentsForm } from '../services';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  addDescField: {
    marginLeft: theme.spacing(3),
    '&>*': {
      height: theme.spacing(4),
      width: theme.spacing(30),
    }
  },
  cell: {
    paddingTop: theme.spacing(0.2),
    paddingBottom: theme.spacing(0.2),
    display: 'flex',
    flexDirection: "row",
    alignItems: 'center'
  }
}));

type InEscrowFieldsTypes = {
  methods: UseFormReturn<ProjectedPaymentsForm, any, undefined>
};

const InEscrowFields = ({
  methods
}: InEscrowFieldsTypes) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { watch, control } = methods;
  const [escrowHomeOwners, escrowTaxes, escrowOthers] = watch(['includes_homeowners', 'includes_taxes', 'includes_other']);
  return (
    <>

      {(!escrowHomeOwners
        && !escrowTaxes
        && !escrowOthers) &&
        <Typography variant="body1">The information here depends on the switches above</Typography>}
      {escrowTaxes && (
        <span className={classes.root}>
          <Controller
            control={control}
            name="escrow_taxes"
            render={({ field }) => (
              <TableCell className={classes.cell}>
                <Switch
                  label=""
                  checked={field.value}
                  handleOnChange={(e) => field.onChange(e.target.checked)}
                />
                <Typography>{t('projectedPayments:propertiesTaxes')}</Typography>
              </TableCell>)}
          />
        </span>
      )}
      {escrowHomeOwners && (
        <span className={classes.root}>
          <Controller
            control={control}
            name="escrow_homeowners"
            render={({ field }) => (
              <TableCell className={classes.cell}>
                <Switch
                  label=""
                  checked={field.value}
                  handleOnChange={(e) => field.onChange(e.target.checked)}
                />
                <Typography>{t('projectedPayments:homeownersInsurance')}</Typography>
              </TableCell>
            )}
          />

        </span>
      )}
      {escrowOthers && (
        <span className={classes.root}>
          <Controller
            control={control}
            name="escrow_other"
            render={({ field }) => (
              <TableCell className={classes.cell}>
                <Switch
                  label=""
                  checked={field.value}
                  handleOnChange={(e) => field.onChange(e.target.checked)}
                />
                <Typography>{t('projectedPayments:other')}</Typography>
              </TableCell>)}
          />
        </span>
      )}
    </>
  );
};

export default InEscrowFields;
