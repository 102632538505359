import React, { FC } from 'react';
import {
  Backdrop,
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import useDisbursments from './hooks/disbursments';
import DisbursmentRow from './components/disbursmentRow';
import SkeletonDisbursments from './components/skeletonDisbursment';
import { useTranslation } from 'react-i18next';
import { DeleteConfirmationModal } from 'components/delete-confirmation';
import ModalAddEntry from './components/modalAddEntry';
import { filteredAccountingLinks } from './services';
import ModalEditEntry from './components/modalEditEntry';
import { formatMoney } from 'utils/helpers';
import CellBalance from './components/cellBalance';

const useStyles = makeStyles((theme: any) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  headerContainer: {
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    color: theme.palette.primary.darker,
    fontSize: 24,
    fontWeight: 500,
  },
  containerEmpty: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `${theme.spacing(3)}px 0`,
  },
  balanced: {
    color: theme.palette.success.main,
    fontSize: 14,
    fontWeight: 600,
  },
  underfunded: {
    color: theme.palette.error.main,
    fontSize: 14,
    fontWeight: 600,
  },
  disburse: {
    color: theme.palette.warning.dark,
    fontSize: 14,
    fontWeight: 600,
  },
  leyendTotals: {
    color: theme.palette.primary.darker,
    fontSize: 14,
    fontWeight: 500,
  },
}));

type kindEntryProp = 'disbursement' | 'receipt' | 'netfunded';

type DisbursmentsProps = {
  kindEntry: kindEntryProp[];
  titleTab: 'ledger' | 'disbursements' | 'receipts' | 'netfunded';
};

const Disbursments: FC<DisbursmentsProps> = ({ kindEntry, titleTab }) => {
  const classes = useStyles();
  const {
    accounting,
    disbursments,
    isLoading,
    modals,
    handleModals,
    handleDeleteEntry,
    isLoadingMutations,
    confirmDelate,
    handleConfirmDelete,
    handleCreationEntry,
    handleEditEntry,
  } = useDisbursments(kindEntry);

  const { openConfirm, callbackSuccess, title, confirmationMessage } = confirmDelate;
  const { t } = useTranslation();
  const tableHeadCell = [
    { id: 'description', label: t('disbursments:description'), hide: false },
    { id: 'amount', label: t('disbursments:amount'), hide: kindEntry.length > 1 },
    { id: 'method', label: t('disbursments:method'), hide: false },
    { id: 'date', label: t('disbursments:date'), hide: false },
    { id: 'accounted-for', label: t('disbursments:accounted-for'), hide: kindEntry.length > 1 },
    { id: 'options', label: t('disbursments:options'), hide: kindEntry.length > 1 },
    { id: 'debit', label: t('disbursments:debit'), hide: kindEntry.length <= 1 },
    { id: 'credit', label: t('disbursments:credit'), hide: kindEntry.length <= 1 },
  ];

  const { totalCredits, totalDebits } = disbursments.reduce(
    (totals, item) => {
      if (item.accounted_for) {
        const sum = item.accounted_for.reduce((acc, num) => acc + (num?.amount ?? 0), 0);
        if (item.kind === 'receipt') totals.totalCredits += sum;
        if (item.kind !== 'receipt') totals.totalDebits += sum;
      }
      return totals;
    },
    { totalCredits: 0, totalDebits: 0 }
  );

  const balance = totalCredits - totalDebits;

  return (
    <Box>
      <Paper>
        {isLoading ? (
          <SkeletonDisbursments />
        ) : (
          <>
            <Box className={classes.headerContainer}>
              <Typography className={classes.title} variant="body1">
                {t(`disbursments:${titleTab}`)}
              </Typography>
              {titleTab !== 'ledger' && (
                <Button variant="contained" color="secondary" onClick={() => handleModals('add')}>
                  {t('disbursments:add-entry')}
                </Button>
              )}
            </Box>
            <TableContainer>
              {disbursments.length > 0 ? (
                <Table>
                  <TableHead>
                    <TableRow>
                      {tableHeadCell
                        .filter((cell) => !cell.hide)
                        .map((cell) => (
                          <TableCell key={cell.id}>{cell.label}</TableCell>
                        ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {disbursments.map((item) => (
                      <DisbursmentRow
                        disbursmentItem={item}
                        key={item._id}
                        handleDeleteEntry={handleDeleteEntry}
                        handleConfirmDelete={handleConfirmDelete}
                        t={t}
                        kindEntry={kindEntry}
                        handleModals={handleModals}
                      />
                    ))}
                    {titleTab === 'ledger' && (
                      <>
                        <TableRow>
                          <TableCell colSpan={2} />
                          <TableCell className={classes.leyendTotals}>
                            {t('disbursments:total')}
                          </TableCell>
                          <TableCell className={classes.leyendTotals}>
                            {formatMoney(totalDebits)}
                          </TableCell>
                          <TableCell className={classes.leyendTotals}>
                            {formatMoney(totalCredits)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2} />
                          <TableCell className={classes.leyendTotals}>
                            {t('disbursments:balance')}
                          </TableCell>
                          {balance === 0 && (
                            <CellBalance
                              className={classes.balanced}
                              balance={balance}
                              text={t('disbursments:balanced')}
                            />
                          )}
                          {balance > 0 && (
                            <CellBalance
                              className={classes.disburse}
                              balance={balance}
                              text={t('disbursments:disburse')}
                            />
                          )}
                          {balance < 0 && (
                            <CellBalance
                              className={classes.underfunded}
                              balance={balance}
                              text={t('disbursments:underfunded')}
                            />
                          )}
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
              ) : (
                <Box className={classes.containerEmpty}>
                  <Typography variant="h6">{t('disbursments:empty-entry')}</Typography>
                </Box>
              )}
            </TableContainer>
            {modals && (
              <>
                {modals.kind === 'add' && (
                  <ModalAddEntry
                    accounting={filteredAccountingLinks(accounting, kindEntry[0])}
                    handleClose={() => {
                      handleModals('');
                    }}
                    t={t}
                    kindEntry={kindEntry[0]}
                    handleCreationEntry={handleCreationEntry}
                  />
                )}
                {modals.kind === 'edit' && (
                  <ModalEditEntry
                    accounting={disbursments.filter((entry) => entry._id === modals.idEntry)[0]}
                    handleClose={() => {
                      handleModals('');
                    }}
                    t={t}
                    handleEditEntry={handleEditEntry}
                    kindEntry={kindEntry[0]}
                  />
                )}
              </>
            )}
          </>
        )}
      </Paper>
      <Backdrop className={classes.backdrop} open={isLoadingMutations}>
        <CircularProgress color="secondary" />
      </Backdrop>

      {callbackSuccess && (
        <DeleteConfirmationModal
          title={title}
          confirmationMessage={confirmationMessage}
          isOpen={openConfirm}
          onConfirm={callbackSuccess}
          onClose={() => handleConfirmDelete(false, null, '', '')}
          isRemoveLoading={false}
        />
      )}
    </Box>
  );
};

export default Disbursments;
