import { Box, Typography } from '@material-ui/core';
import DatePicker from 'components/date-picker/date-picker';
import NumberField from 'components/number-field';
import SelectField from 'components/select-field';
import TextField from 'components/text-field';
import React, { FC } from 'react';
import { Controller, UseFormReturn, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HUD_1 } from 'utils/constants';
import { LoanFormType } from 'views/orders/loans/types';
import { loanPurpose, loanTypes } from 'views/orders/loans/utils/constants';

import { useStyles } from './styles';

type Props = {
  loanForm: UseFormReturn<LoanFormType, object>,
  isDataLoading: boolean,
  orderType: string
};

export const TermsAndDates: FC<Props> = ({
  loanForm,
  isDataLoading,
  orderType
}) => {
  const { control } = loanForm;
  const { loan_number } = useWatch({ control });
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <Box style={{ display: 'flex', gap: '20px' }}>
        <Controller
          control={control}
          name="loan_number"
          render={({ field }) => (
            <TextField
              color="secondary"
              label={<Typography variant="body2">{t('orders:loan-number')}</Typography>}
              name="loan_id"
              handleBlur={field.onBlur}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />

        <Controller
          control={control}
          name="insurance_case"
          key={`insurance_case_${loan_number}`}
          render={({ field }) => (
            <TextField
              color="secondary"
              label={<Typography variant="body2">{t('loan:case-inst-mortgage')}</Typography>}
              name="mortgage_ins_case"
              handleBlur={field.onBlur}
              value={field.value}
              onChange={field.onChange}
              disabled={isDataLoading}
            />
          )}
        />
      </Box>

      <Box style={{ display: 'flex', gap: '20px' }}>
        <Controller
          control={control}
          name="terms"
          render={({ field }) => (
            <SelectField
              label={t('loan:loan_type')}
              value={field.value || ''}
              handleBlur={field.onBlur}
              handleChange={(e) => field.onChange(e.target.value)}
              data={loanTypes}
              dataKey=""
              dataValue=""
              dataText=""
              disabled={isDataLoading}
            />
          )}
        />

        {orderType !== HUD_1 && (
          <Controller
            control={control}
            name="purpose"
            render={({ field }) => (
              <SelectField
                label={t('loan:purpose')}
                value={field.value || ''}
                handleBlur={field.onBlur}
                handleChange={(e) => field.onChange(e.target.value)}
                data={loanPurpose}
                dataKey=""
                dataValue=""
                dataText=""
                disabled={isDataLoading}
                inputLabelProps={{ shrink: !!field.value }}
              />
            )}
          />
        )}
      </Box>

      {orderType !== HUD_1 && (
        <>
          <Controller
            control={control}
            name="product"
            key={`product_${loan_number}`}
            render={({ field }) => (
              <TextField
                color="secondary"
                label={<Typography variant="body2">{t('loan:product')}</Typography>}
                name="product"
                handleBlur={field.onBlur}
                value={field.value}
                onChange={field.onChange}
                disabled={isDataLoading}
              />
            )}
          />

          <Box style={{ display: 'flex', gap: '20px' }}>
            <Controller
              control={control}
              name="buyer_issue_date"
              render={({ field }) => (
                <DatePicker
                  label={t('loan:buyer-issue-date')}
                  name="buyer-cd-issue-date"
                  error={false}
                  handleBlur={field.onBlur}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <Controller
              control={control}
              name="seller_issue_date"
              render={({ field }) => (
                <DatePicker
                  label={t('loan:seller-issue-date')}
                  name="seller-cd-issue-date"
                  error={false}
                  handleBlur={field.onBlur}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </Box>
        </>
      )}

      <Box style={{ display: 'flex', gap: '20px' }}>
        <Controller
          control={control}
          name="payment_first_date"
          render={({ field }) => (
            <DatePicker
              label={t('loan:first-payment-date')}
              name="first-payment"
              error={false}
              handleBlur={field.onBlur}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="payment_last_date"
          render={({ field }) => (
            <DatePicker
              label={t('loan:last-payment-date')}
              name="last-payment"
              error={false}
              handleBlur={field.onBlur}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </Box>

      <Box style={{ display: 'flex', gap: '20px' }}>
        <Controller
          control={control}
          name="term_years"
          render={({ field }) => (
            <NumberField
              variant="filled"
              color="secondary"
              name="loan_term_years"
              handleBlur={field.onBlur}
              disabled
              value={field.value}
              InputProps={{
                className: classes.moyearAdornment,
                endAdornment: (
                  <Box className={classes.endAdornmentStyle}>
                    <Typography variant="body2">{t('loan:years')}</Typography>
                  </Box>
                ),
                classes: { input: classes.stretchInput },
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="term_months"
          render={({ field }) => (
            <NumberField
              variant="filled"
              color="secondary"
              name="loan_term_months"
              handleBlur={field.onBlur}
              disabled
              value={field.value}
              InputProps={{
                className: classes.moyearAdornment,
                endAdornment: (
                  <Box className={classes.endAdornmentStyle}>
                    <Typography variant="body2">{t('loan:Mo')}</Typography>
                  </Box>
                ),
                classes: { input: classes.stretchInput },
              }}
            />
          )}
        />
      </Box>

      <Box style={{ display: 'flex', gap: '20px' }}>
        <Controller
          control={control}
          name="commitment_date"
          render={({ field }) => (
            <DatePicker
              label={t('loan:mortgage-commitment-date')}
              name="mortgage-commitment-date"
              error={false}
              handleBlur={field.onBlur}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />

        <Controller
          control={control}
          name="grace_days"
          render={({ field }) => (
            <NumberField
              variant="filled"
              color="secondary"
              name="grace-period-days"
              handleBlur={field.onBlur}
              value={field.value}
              label="Grace Period Days"
              onChange={(e) => field.onChange(Number(e.target.value))}
            />
          )}
        />
      </Box>

      <Controller
        control={control}
        name="late_payment_days"
        render={({ field }) => (
          <NumberField
            variant="filled"
            color="secondary"
            name="late-payment-days"
            handleBlur={field.onBlur}
            label={t('loan:days-passed')}
            value={field.value}
            onChange={(e) => field.onChange(Number(e.target.value))}
          />
        )}
      />
    </Box>
  );
};
