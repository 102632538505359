import { Box, Grid, makeStyles, Paper } from '@material-ui/core';
import { ListingEntry } from 'graphql/listings/queries';
import React from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { NewOrderPartyType } from 'v2-types/order';

import AutoSave from './components/autosave';
import FormAmounts from './components/form-amounts';
import FormClosingDate from './components/form-closing-date';
import FormConfig from './components/form-config';
import FormType from './components/form-type';
import Header from './components/header';
import PlaceOfClosing from './components/place-of-closing';
import { DatesAndConfigurationForm, updateType } from './services';

const useStyles = makeStyles((theme) => ({
  rootContainer: { padding: theme.spacing(3) },
  mainContainer: { padding: theme.spacing(3) },
  formSpacing: { marginTop: theme.spacing(3) },
}));

type DatesAndConfigSceneProps = {
  transactionTypes: ListingEntry[];
  workflows: ListingEntry[];
  settelmentStatements: ListingEntry[];
  handleSave: (data: DatesAndConfigurationForm) => void;
  totalLoanAmount: number,
  totalPurchasePrice: number,
  methods: UseFormReturn<DatesAndConfigurationForm, object>,
  parties: Pick<NewOrderPartyType, 'name' | '_id' | 'accounts' | 'kinds'>[],
  saveStatus?: updateType,
};

const DatesAndConfigScene = ({
  transactionTypes,
  workflows,
  settelmentStatements,
  totalLoanAmount,
  totalPurchasePrice,
  methods,
  parties,
  handleSave,
  saveStatus
}: DatesAndConfigSceneProps) => {
  const classes = useStyles();
  return (
    <FormProvider {...methods}>

      <Box className={classes.rootContainer}>
        <Paper>
          <Grid container direction="column">
            <Grid item xs={12}>
              <Header latestUpdate={saveStatus} />
            </Grid>
            <Grid item xs={12} className={classes.mainContainer}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormType
                        transactionTypes={transactionTypes}
                      />
                    </Grid>

                    <Grid item xs={12} className={classes.formSpacing}>
                      <FormClosingDate />
                    </Grid>

                    <Grid item xs={12} className={classes.formSpacing}>
                      <FormAmounts
                        totalLoanAmount={totalLoanAmount}
                        totalPurchasePrice={totalPurchasePrice}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <PlaceOfClosing parties={parties} />
                    </Grid>
                    <Grid item xs={12} className={classes.formSpacing}>
                      <FormConfig
                        workflows={workflows}
                        settelmentStatements={settelmentStatements}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <AutoSave handleSave={handleSave} />
      </Box>

    </FormProvider>
  );
};

export default DatesAndConfigScene;
