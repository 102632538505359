import { AccountingType } from 'v2-types/order';
import * as yup from 'yup';

export const schemaModalAddEntry = yup.object({
  description: yup.string().required('required'),
  entryDate: yup.date().typeError('error-date'),
  targets: yup
    .array()
    .of(
      yup.object().shape({
        checked: yup.boolean(),
        amount: yup.number().positive('error-amount'),
      })
    )
    .default([])
    .test('at-least-one-checked', '', (targets) =>
      targets.some((target) => target.checked === true)
    )
    .required('required'),
});

export const schemaModalEditEntry = yup.object({
  description: yup.string().required('required'),
  entry_date: yup.date().typeError('error-date'),
});

export const filterEntriesToDisbursment = (accounting: AccountingType[], kindEntry: string[]) => {
  const filteredDisbursments = accounting
    .filter((entry) => kindEntry.includes(entry.kind))
    .sort((a, b) => a.description.localeCompare(b.description));

  const accountingFiltered = accounting
    .map((entry) => ({
      ...entry,
      description:
        entry.letter && entry.number
          ? `${entry.description} (${entry.letter}-${entry.number})`
          : entry.description,
      involved_parties: entry.involved_parties.filter(
        (party) =>
          party.amount > 0 && party.kind === 'Payee' && party.accounted_amount < party.amount
      ),
    }))
    .filter((entry) => entry.involved_parties.length > 0)
    .sort((a, b) => a.description.localeCompare(b.description));
  return {
    filteredDisbursments,
    accountingFiltered,
  };
};

const allowedLinkedDisb = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];

const allowedLinkedReceipts = [
  'down_payment',
  'earnest',
  'excess_earnest',
  'loan_amount',
  'option_fee',
];

export const filteredAccountingLinks = (accounting: AccountingType[], kindEntry: string) => {
  return accounting.filter((accEntry) => {
    if (kindEntry === 'receipt') {
      return allowedLinkedReceipts.includes(accEntry.code);
    }
    return allowedLinkedDisb.includes(accEntry.letter);
  });
};
