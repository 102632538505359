import { Box, Typography } from '@material-ui/core';
import { TextButton } from 'components/TextButton';
import { useGetListings } from 'hooks/useGetListings';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Loan } from 'v2-types/order';

import { useStyles } from '../../styles';
import { ModalData } from '../../types';
import { Content } from './components/content';

type Props = {
  isDataLoading: boolean,
  isUpdateLoading: boolean,
  selectedLoan?: Loan,
  setModalData: React.Dispatch<React.SetStateAction<ModalData | undefined>>,
  orderType: string
};

export const Interests: FC<Props> = ({
  isDataLoading,
  isUpdateLoading,
  selectedLoan,
  setModalData,
  orderType
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { data: kindListingResponse, isLoading: isLoadingListing } = useGetListings({
    code: 'allowed_interest_blocks',
  });
  const kindListing = kindListingResponse?.getListing.entries ?? [];

  return (
    <Box>
      <Box
        style={{
          display: 'flex',
          gap: '20px',
          height: '50px',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h6" className={classes.subTitle}>
          {t('loan:interest')}
        </Typography>
        <TextButton
          icon="plus"
          text={t('loan:add-interests')}
          textColor="#FB8C00"
          disabled={isDataLoading || isUpdateLoading}
          onClick={() => setModalData({ modal: 'createInterest' })}
        />
      </Box>
      <Content
        isLoading={isDataLoading || isLoadingListing}
        setModalData={setModalData}
        selectedLoan={selectedLoan}
        kindListing={kindListing}
        orderType={orderType}
      />
    </Box>
  );
};
