import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core';
import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { editEntryForm } from '../types';
import SelectField from 'components/select-field';
import { AccountingType } from 'v2-types/order';
import { TFunction } from 'i18next';
import TextField from 'components/text-field';
import DatePicker from 'components/date-picker/date-picker';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaModalEditEntry } from '../services';

type ModalEditEntryProps = {
  accounting: AccountingType;
  handleClose: () => void;
  t: TFunction;
  kindEntry: 'disbursement' | 'receipt' | 'netfunded';
  handleEditEntry: (data: editEntryForm, id: string) => void;
};

const allowedAssociations = [
  {
    code: 'cash',
    description: 'Cash',
  },
  {
    code: 'check',
    description: 'Check',
  },
  {
    code: 'wire',
    description: 'Wire Transfer',
  },
];

export const ModalEditEntry: FC<ModalEditEntryProps> = ({
  handleClose,
  accounting,
  t,
  handleEditEntry,
  kindEntry,
}) => {
  const methods = useForm<editEntryForm>({
    resolver: yupResolver(schemaModalEditEntry),
    defaultValues: {
      code: accounting.code,
      description: accounting.description,
      entry_date: accounting.entry_date,
    },
    mode: 'onChange',
  });
  const {
    handleSubmit,
    control,
    formState: { isValid, errors, isDirty },
    trigger,
  } = methods;

  const handleSubmitForm = handleSubmit((data) => {
    handleEditEntry(data, accounting._id);
    handleClose();
  });

  return (
    <Dialog open fullWidth maxWidth="md">
      <DialogTitle>{t('disbursments:editLinkEntry')}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmitForm}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="description"
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    value={field.value}
                    inputRef={ref}
                    label={t('disbursments:description')}
                    handleBlur={() => {
                      trigger('description');
                    }}
                    error={!!errors.description}
                    helperText={
                      errors.description?.message
                        ? t(`disbursments:${errors.description.message}`)
                        : ''
                    }
                  />
                )}
              />
            </Grid>
            {kindEntry !== 'netfunded' && (
              <Grid item xs={3}>
                <Controller
                  control={control}
                  name="code"
                  render={({ field: { ref, ...field } }) => (
                    <SelectField
                      {...field}
                      value={field.value || 'cash'}
                      inputRef={ref}
                      data={allowedAssociations}
                      dataKey="code"
                      dataValue="code"
                      dataText="description"
                      label={t('users:kind')}
                      handleBlur={() => {
                        trigger('code');
                      }}
                      handleChange={field.onChange}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={kindEntry !== 'netfunded' ? 3 : 6}>
              <Controller
                name="entry_date"
                control={control}
                render={({ field: { ref, value, ...field } }) => (
                  <DatePicker
                    {...field}
                    inputRef={ref}
                    disableToolbar={false}
                    label={t('accounting:entry-date')}
                    value={value as string | Date}
                    error={!!errors.entry_date}
                    helperText={
                      errors.entry_date?.message
                        ? t(`disbursments:${errors.entry_date.message}`)
                        : ''
                    }
                  />
                )}
              />
            </Grid>
          </Grid>

          <DialogActions style={{ margin: '20px 0 10px', padding: '0' }}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => handleClose()}
              disableElevation
            >
              <Typography variant="body2">{t('disbursments:close')}</Typography>
            </Button>
            <Button
              disabled={!isValid || !isDirty}
              color="secondary"
              variant="contained"
              type="submit"
              disableElevation
            >
              <Typography variant="body2">{t('disbursments:edit')}</Typography>
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ModalEditEntry;
