/* eslint-disable object-curly-newline */

import {
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import EndorsementSearch from './endorsement-search';

const useStyles = makeStyles((theme: any) => ({
  title: { color: theme.palette.tab.offselected },
  chipContainer: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    backgroundColor: theme.accent.main,
  },
  chipText: {
    color: theme.palette.primary.darker,
    fontWeight: 'bold',
  },
  captionContainer: { marginLeft: theme.spacing(5) },
  captionText: { color: theme.palette.text.shadow },
}));

const TableEndorsementsHeader = ({
  selectedPolicy,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="center">
      <div>
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <Typography variant="h6" className={classes.title}>
              {t('policyInfoAndRates:endorsements')}
            </Typography>
          </Grid>

          <Grid item className={classes.chipContainer}>
            <Typography variant="body2" className={classes.chipText}>
              {selectedPolicy === 'owners_policy' ? t('policyInfoAndRates:owner') : t('policyInfoAndRates:lender')}
            </Typography>
          </Grid>

        </Grid>
      </div>

      <EndorsementSearch />
    </Grid>
  );
};

export default TableEndorsementsHeader;
