import { Box, Tabs } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import { CustomTab as Tab } from 'components/common/custom-tab';
import React, { FC, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { HUD_1 } from 'utils/constants';

import { LoanFormType } from '../../types';
import { Disclosures } from './components/disclosures';
import { Increases } from './components/increases';
import { TermsAndDates } from './components/terms-and-dates';
import { useStyles } from './styles';

type Props = {
  loanForm: UseFormReturn<LoanFormType, object>,
  isDataLoading: boolean,
  orderType: string
}

// eslint-disable-next-line no-shadow
enum TabValue {
  termsAndDates = 'terms-and-dates',
  increases = 'increases',
  disclosure = 'disclosure'
}

export const TermsAndDatesIncreasesDisclosure: FC<Props> = ({
  loanForm,
  isDataLoading,
  orderType
}) => {
  const [selectedTab, setSelectedTab] = useState<TabValue>(TabValue.termsAndDates);
  const classes = useStyles();

  return (
    <TabContext value={selectedTab}>
      <Box>
        <Tabs value={selectedTab} onChange={(e, value: TabValue) => setSelectedTab(value)}>
          <Tab
            value={TabValue.termsAndDates}
            text="Terms & Dates"
            currentValue={selectedTab}
          />
          {orderType !== HUD_1 && (
            <>
              <Tab
                value={TabValue.increases}
                text="Increases"
                currentValue={selectedTab}
              />
              <Tab
                value={TabValue.disclosure}
                text="Disclosures"
                currentValue={selectedTab}
              />
            </>
          )}
        </Tabs>

        <TabPanel value={TabValue.termsAndDates} className={classes.panelcontainer}>
          <TermsAndDates
            loanForm={loanForm}
            isDataLoading={isDataLoading}
            orderType={orderType}
          />
        </TabPanel>

        {orderType !== HUD_1 && (
          <>
            <TabPanel value={TabValue.increases} className={classes.panelcontainer}>
              <Increases
                loanForm={loanForm}
              />
            </TabPanel>
            <TabPanel value={TabValue.disclosure} className={classes.panelcontainer}>
              <Disclosures
                loanForm={loanForm}
                isDataLoading={isDataLoading}
              />
            </TabPanel>
          </>
        )}
      </Box>
    </TabContext>
  );
};
