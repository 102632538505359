import { Collapse, TableCell } from '@material-ui/core';
import Actionbuttons from 'components/common/actions-buttons';
import React from 'react';
import { TFunction } from 'react-i18next';
import { Column, RendererParams } from 'types/common';
import { capitalize, IntlCurrencyFormat } from 'utils/helpers';
import { AccountingType } from 'v2-types/order';

import { ColumnsType } from '../../../services';
import AccountedFor from '../components/accounted-for';
import InvolvedPartisTable from '../components/involved-parties-table';
import SimpleCellText from '../components/simple-text-cell';

export const ledgerColumns = (
  t: TFunction,
  handleEdit: (row: any) => void,
  handleCollapseRow: (row: any) => void,
  itemsCollapsed: { [key: string]: boolean; },
  handleAddParty: (row: any) => void,
  handleDeleteLedger: (row: any) => void,
  handleCreateLink: (open: boolean) => void
): ColumnsType => ({
  parties: {
    header: {
      title: t('accounting:actions'),
      width: '128px',
      minWidth: '128px'
    },
    renderer: ({ row }) => {
      const enableDelte = row.kind.includes('manual')
        || row.kind.includes('disbursement')
        || row.kind.includes('receipt');
      return (
        <Actionbuttons
          shouldCollapse={itemsCollapsed[row?.id]}
          handleAdd={() => {
            handleAddParty(row);
          }}
          handleCollapse={(e) => {
            e.stopPropagation();
            handleCollapseRow(row);
          }}
          handleEdit={(e) => {
            e.stopPropagation();
            handleEdit(row);
          }}
          handleDelete={enableDelte ? (e) => {
            e.stopPropagation();
            handleDeleteLedger(row);
          } : undefined}
          handleChain={(e) => {
            e.stopPropagation();
            handleCreateLink(row);
          }}
        />
      );
    }
  },
  kind: {
    header: {
      title: t('accounting:kind'),
      align: 'left',
      width: '100px',
      minWidth: '100px'
    },
    renderer: ({ row }: RendererParams) => (
      <SimpleCellText textAlign="left">{capitalize(row.kind)}</SimpleCellText>
    ),
  },
  code: {
    header: {
      title: t('accounting:code'),
      align: 'left',
      width: '150px',
      minWidth: '150px'
    },
    renderer: ({ row }: RendererParams) => (
      <SimpleCellText textAlign="left">{row.code}</SimpleCellText>
    ),
  },
  description: {
    header: {
      title: t('accounting:description'),
      align: 'left'
    },
    renderer: ({ row }: RendererParams) => (
      <SimpleCellText textAlign="left">{row?.description}</SimpleCellText>
    ),
  },
  amount: {
    header: {
      title: t('accounting:amount'),
      width: '100px',
      minWidth: '100px',
      align: 'right'
    },
    renderer: ({ row }: RendererParams) => (
      // eslint-disable-next-line radix
      <SimpleCellText textAlign="right">{IntlCurrencyFormat(parseFloat(row.amount))}</SimpleCellText>
    ),
  },
  letter: {
    header: {
      title: t('accounting:letter'),
      width: '90px',
      minWidth: '90px',
      align: 'center'
    },
    renderer: ({ row }: RendererParams) => (
      <SimpleCellText textAlign="center">{row?.letter}</SimpleCellText>
    ),
  },
  entryDate: {
    header: {
      title: t('accounting:entry-date'),
      width: '150px',
      minWidth: '150px'
    },
    renderer: ({ row }: RendererParams) => (
      <SimpleCellText textAlign="center">{(row?.entryDate && row?.entryDate.split('T')[0]) || 'Not Defined Yet'}</SimpleCellText>
    ),
  },
  number: {
    header: {
      title: 'No.',
      width: '71px',
      minWidth: '71px'
    },
    renderer: ({ row }: RendererParams) => (
      <SimpleCellText textAlign="center">{row?.number || ''}</SimpleCellText>
    ),
  },
  accountedFor: {
    header: {
      title: t('accounting:accounted-for'),
      width: '250px',
      minWidth: '250px'
    },
    renderer: ({ row }: RendererParams) => (
      <AccountedFor accountedInfo={row?.accounted_for || []} />
    ),
  },
});

type generateCollapseReturn = (
  handleEditLedgerAssociation: (row: any) => void,
  handleDeleteLedgerAssociation: (row: any) => void,
) => Column[]
export const generateCollapseRow: generateCollapseReturn = (
  handleEditLedgerAssociation: (row: any) => void,
  handleDeleteLedgerAssociation: (row: any) => void,
) => ([
  {
    renderer: ({ row, index, shouldCollapse }) => (
      <TableCell
        key={`collapse-column-${index}`}
        colSpan={8}
        style={{ background: 'white' }}
      >
        <Collapse in={shouldCollapse} timeout="auto" unmountOnExit>
          <InvolvedPartisTable
            ledgerRow={row}
            handleEditLedgerAssociation={handleEditLedgerAssociation}
            handleDeleteLedgerAssociation={handleDeleteLedgerAssociation}
          />
        </Collapse>
      </TableCell>
    ),
  },
]);

export const parseLedgerColumns = (data: AccountingType[]) => data.map((account) => ({
  id: account._id,
  code: account.code,
  description: account.description,
  amount: account.amount,
  letter: account.letter,
  involved_parties: account.involved_parties || [],
  kind: account.kind,
  amount_override: account.amount_override,
  entryDate: account.entry_date,
  amount_calculated: account.amount_calculated,
  number: account.number,
  months: account.months,
  accounted_for: account?.accounted_for || []
}));

export default {};
