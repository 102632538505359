import gqlRequest from 'graphql/generic';
import { PartyAccounting } from 'types/ledger';
import { AccountingType } from 'v2-types/order';

type GetOrder = {
  getOrder: {
    accounting: AccountingType[];
    parties: PartyAccounting[];
  };
};

type GetOrderParams = {
  id: string;
};

const query = `
  query GetOrder($id: ID!) {
    getOrder(_id: $id) {
      accounting {
        _id
        code
        description
        entry_date
        amount
        letter
        number
        kind
        involved_parties {
          _id
          name
          order_kinds
          kind
          amount
          accounted_amount
        }
        accounted_for {
          amount
          record {
            _id
            description
            letter
            number
            amount
            involved_parties {
              _id
              name
              kind
              order_kinds
              accounted_amount
            }
          }
        }
      }
      parties {
        _id
      }
    }
  }
`;

export const getOrder = (id: string) => gqlRequest<GetOrder, GetOrderParams>(query, { id });
