import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

import { getPaymentsRows, ProjectedPaymentsForm } from '../services';
import ProjectedPaymentsTable from './projected-payments-table';
import { useTranslation } from 'react-i18next';
import { UseFormReturn } from 'react-hook-form';

const useStyles = makeStyles((theme: any) => ({
  subTitle: {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
}));

type PatymentsSectionTableType = {
  methods: UseFormReturn<ProjectedPaymentsForm, any, undefined>,
  onEditMortgage: any,
  onEditEstimated: any
  calculatedMortgageValue: number;
  calculatedEstimatedEscrow: number;
}

const PaymentSectionTable = ({
  methods,
  onEditEstimated,
  onEditMortgage,
  calculatedEstimatedEscrow,
  calculatedMortgageValue
}: PatymentsSectionTableType) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const fields = [
    'year_ranges',
    'principal_interest_amount',
    'interest_only',
    'mortgage_insurance',
    'estimated_escrow'
  ];
  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <Typography variant="body2" className={classes.subTitle}>
          {`${t('projectedPayments:years-range-title')}`}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <ProjectedPaymentsTable
          data={fields}
          rows={getPaymentsRows({
            ...fields,
            methods,
            t,
            onEditMortgage,
            onEditEstimated,
            calculatedMortgageValue,
            calculatedEstimatedEscrow
          })}
        />
      </Grid>
    </Grid>
  );
};

export default PaymentSectionTable;
