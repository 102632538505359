import React, {
  createContext,
  FC,
  useContext,
} from "react";
import { MutateFunction } from "react-query";
import { AccountingType } from "v2-types/order";

import useGetCharges from "views/orders/new-charges/services/getCharges";
import { UpdateOrder, UpdateOrderParams } from "views/orders/new-charges/services/mutations";

type ChargesContextType = {
  accounting: AccountingType[],
  updateAccounting: (data: AccountingType[]) => void,
  isFetching: boolean,
  setHideTax: React.Dispatch<React.SetStateAction<boolean>>,
  hideTax: boolean,
  policyIsLoading: boolean,
  showNewInfoAlert: boolean,
  reloadAmounts: () => void,
  chargesLetter: string,
  redirectToPolicyInfo: () => void,
  discloSection: string,
  setChargesSection: MutateFunction<UpdateOrder, unknown, UpdateOrderParams, unknown>,
  filterCharges: (charges: AccountingType[]) => AccountingType[]
}

const ChargesContext = createContext<ChargesContextType | null>(null);

export const ChargesProvider: FC<{ chargesLetter: string, children: React.ReactNode }> = ({ chargesLetter, children }) => {
  const {
    accounting,
    updateAccounting,
    isFetching,
    setHideTax,
    hideTax,
    policyIsLoading,
    showNewInfoAlert,
    reloadAmounts,
    redirectToPolicyInfo,
    discloSection,
    setChargesSection,
    filterCharges
  } = useGetCharges(chargesLetter);

  return (
    <ChargesContext.Provider value={{
      accounting,
      updateAccounting,
      isFetching,
      setHideTax,
      hideTax,
      policyIsLoading,
      showNewInfoAlert,
      reloadAmounts,
      chargesLetter,
      redirectToPolicyInfo,
      discloSection,
      setChargesSection,
      filterCharges
    }}
    >
      {children}
    </ChargesContext.Provider>
  );
};

export const useChargesContext = () => {
  const methods = useContext(ChargesContext)!;
  return methods;
};