import { ListingEntry } from "graphql/listings/queries";
import { HUD_1 } from "utils/constants";
import { InterestKindEnum } from "v2-types/order";

type Params = {
  kindList: ListingEntry[],
  orderType: string
}

export const filterKindList = ({
  kindList,
  orderType
}: Params) => {
  return kindList.filter((kind) => {
    if (kind.code === InterestKindEnum.only && orderType === HUD_1) return false;

    return true;
  });
};