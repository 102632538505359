import { Interest, InterestKindEnum } from 'v2-types/order';
import { HUD_1 } from 'utils/constants';

type Params = {
  interestes: Interest[],
  orderType: string
}

export function filterInterestes({ interestes, orderType }: Params) {
  return interestes.filter((interest) => {
    if (interest.kind === InterestKindEnum.only && orderType === HUD_1) return false;

    return true;
  });
}
