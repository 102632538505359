import { Grid, Typography, useTheme, makeStyles } from "@material-ui/core";
import React, { FC } from "react";
import { AccountingInvolvedParty } from "v2-types/order";
import TableComponent from 'components/Table';
import { TFunction } from "i18next";
import Actionbuttons from "components/common/actions-buttons";
import { TextButton } from "components/TextButton";

const useStyles = makeStyles((theme: any) => ({
  primarycolorfonts: {
    fontWeight: 500,
    color: theme.palette.tab.offselected,
  }
}));

type Props = {
  purchasePriceEntries: AccountingInvolvedParty[],
  t: TFunction,
  setOpenPartyLedgerModal: React.Dispatch<React.SetStateAction<boolean>>,
  setPartyLedgerEntry: React.Dispatch<React.SetStateAction<AccountingInvolvedParty | undefined>>,
  onRemoveOrderPartyEntry: (entry: AccountingInvolvedParty) => void
}

export const EarnestAndCommissions: FC<Props> = ({
  purchasePriceEntries,
  t,
  setOpenPartyLedgerModal,
  setPartyLedgerEntry,
  onRemoveOrderPartyEntry
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Grid item xs container direction="column" spacing={2}>
      <Grid item container justifyContent="space-between">
        <Typography variant="h6" className={classes.primarycolorfonts}>
          {t('earnestAndCommissions:earnest-and-commissions')}
        </Typography>
        <TextButton
          text={t('accounting:add-entry')}
          icon="plus"
          textColor="#FB8C00"
          onClick={() => setOpenPartyLedgerModal(true)}
        />
      </Grid>
      {purchasePriceEntries.length > 0 && (
        <Grid item xs>
          <TableComponent
            disabledBorderLeft
            cellAlignment="left"
            columns={{
              actions: {
                header: { title: t('accounting:actions') },
                renderer: ({ row }) => (
                  <Actionbuttons
                    handleEdit={() => {
                      setPartyLedgerEntry(row as AccountingInvolvedParty);
                      setOpenPartyLedgerModal(true);
                    }}
                    handleDelete={() => {
                      const partyEntry = row as AccountingInvolvedParty;
                      onRemoveOrderPartyEntry(partyEntry);
                    }}
                  />
                ),
              },
              name: {
                header: { title: t('accounting:name') },
                renderer: ({ row }) => <>{row.name}</>,
              },
              kind: {
                header: { title: t('accounting:kind') },
                renderer: ({ row }) => <>{row.kind}</>,
              },
              amount: {
                header: { title: t('accounting:amount') },
                renderer: ({ row }) =>
                  <>
                    {`$${Number(row.amount).toLocaleString('en-US')}`}
                  </>,
              },
            }}
            dataSet={purchasePriceEntries}
          />
        </Grid>
      )}
    </Grid>
  );
};