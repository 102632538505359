/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';

import useProjectedPayments from './projected-payments-hook';
import ProjectedPaymentsScene from './projected-payments-scene';
import { ProjectedPaymentsForm } from './services';

const ProjectedPayments = () => {
  const {
    methods,
    updateProjectedPayments,
    updateOrderProjectedResponse,
    orderId,
    currentEntry,
    setCurrentEntry,
    estimatedEscrowEntry,
    mortgageInsuranceEntry,
    referenceEstimatedValue,
    referenceMortgageValue,
    isLoadingMortgageInsurance,
    updateAccountingEntries
  } = useProjectedPayments();

  const handleEditMortgage = useCallback(() => {
    setCurrentEntry(mortgageInsuranceEntry);
  }, [mortgageInsuranceEntry]);

  const handleEditEstimated = useCallback(() => {
    setCurrentEntry(estimatedEscrowEntry);
  }, [estimatedEscrowEntry]);

  const handleSave = async (values: ProjectedPaymentsForm) => {
    const yearRanges = values.year_ranges.filter(yearRange => yearRange.entryId !== "");
    if (yearRanges.length > 0 && methods.formState.dirtyFields.year_ranges) {
      await updateAccountingEntries({
        id: orderId,
        entries: yearRanges.map((yearRange) => ({
          accountingId: yearRange.entryId,
          data: {
            amount_override: yearRange.amount,
            entry_date: null,
            letter: null
          }
        }))
      });
    }

    updateProjectedPayments({
      id: orderId,
      data: {
        principal_kind: values.principal_kind,
        interest_only: values.interest_only,
        prorations_period: values.prorations_period,
        includes_taxes: values.includes_taxes,
        includes_homeowners: values.includes_homeowners,
        includes_other: values.includes_other,
        other_description: values.other_description,
        escrow_homeowners: values.escrow_homeowners,
        escrow_taxes: values.escrow_taxes,
        escrow_other: values.escrow_other,
        year_ranges: values.year_ranges.map(yearRange => yearRange.range),
        principal_amount: Number(values.principal_amount),
        principal_min: Number(values.principal_min),
        principal_max: Number(values.principal_max),
        prorations_amount: Number(values.prorations_amount),
      }
    });
  };

  return (
    <ProjectedPaymentsScene
      methods={methods}
      loadingSave={updateOrderProjectedResponse.isLoading}
      handleSave={handleSave}
      handleEditEstimated={handleEditEstimated}
      handleEditMortgage={handleEditMortgage}
      currentEntry={currentEntry}
      setCurrentEntry={setCurrentEntry}
      estimatedCalculatedValue={referenceEstimatedValue.current}
      mortgageCalculatedValue={referenceMortgageValue.current}
      isLoadingMortgageInsurance={isLoadingMortgageInsurance}
    />
  );
};

export default ProjectedPayments;
