import { Box, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { v4 } from 'uuid';

import React from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: `${theme.spacing(2)}px`,
  },
  containerItems: {
    display: 'flex',
    gap: '10px',
    marginBottom: '10px',
  },
}));

const SkeletonCharges = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Box className={classes.containerItems}>
        <Skeleton variant="text" height={60} width={100} />
        <Skeleton variant="text" height={60} width={100} />
        <Skeleton variant="text" height={60} width={300} />
        <Skeleton variant="text" height={60} width={300} />
        <Skeleton variant="text" height={60} width={50} />
        {Array.from({ length: 5 }).map(_ => (
          <Skeleton variant="text" height={60} style={{ flex: 1 }} />
        ))}
      </Box>
      {Array.from({ length: 8 }).map((_) => (
        <Box key={v4()} className={classes.containerItems}>
          <Skeleton variant="rect" height={60} width={100} />
          <Skeleton variant="rect" height={60} width={100} />
          <Skeleton variant="rect" height={60} width={300} />
          <Skeleton variant="rect" height={60} width={300} />
          <Skeleton variant="rect" height={60} width={50} />
          {Array.from({ length: 5 }).map(__ => (
            <Skeleton variant="rect" height={60} style={{ flex: 1 }} />
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default SkeletonCharges;
