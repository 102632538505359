import { useChargesContext } from 'contexts/chargesContext';
import React from 'react';
import DocumentsDetails from './documents-details';

const ContainerUpdatedocuments = ({ handleIsLoading }: { handleIsLoading: (value: boolean) => void; }) => {
  const {
    accounting,
    updateAccounting,
  } = useChargesContext();

  return (
    <DocumentsDetails
      handleIsLoading={handleIsLoading}
      accounting={accounting}
      updateAccounting={updateAccounting}
      letterDocuments="E"
    />
  );
};

export default ContainerUpdatedocuments;