import { Button, ButtonGroup, Grid, makeStyles } from '@material-ui/core';
import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { REFINANCE } from 'utils/constants';
import { usePolicyInfoContext } from '../contexts/policyInfoContext';

import {
  getAmount,
  LENDERS_ACTUAL,
  LENDERS_DISCLOSED,
  LENDERS_FEES,
  LENDERS_TOTAL,
  LendersPolicy,
  OWNERS_ACTUAL,
  OWNERS_DISCLOSED,
  OWNERS_FEES,
  policyData,
  policyDataRefinance,
  RefinanceCredits,
  SUM_LENDERS_ENDORSEMENTS,
  SUM_OWNERS_ENDORSEMENTS
} from '../services';
import BalanceInfo from './balance-info';

const useStyles = makeStyles((theme) => ({
  container: { padding: theme.spacing(3) },
  buttonsContainer: { marginBottom: theme.spacing(4) },
  button: { textTransform: 'capitalize' },
  spacerContainer: { marginTop: theme.spacing(2) },
}));

type BalanceProps = {
  typeTransaction: string;
};

const Balance: FC<BalanceProps> = ({ typeTransaction }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [tab, setTab] = useState<'disclosed' | 'actual'>('disclosed');

  const context = usePolicyInfoContext();
  const { accounting } = context || {};

  const policyBalance = useMemo(() => {
    const lendersActual = getAmount(
      accounting?.find((acc) => acc.code.includes(LENDERS_ACTUAL))
    );
    const lendersDisclosed = getAmount(
      accounting?.find((acc) => acc.code.includes(LENDERS_DISCLOSED))
    );
    const lendersFees = getAmount(
      accounting?.find((acc) => acc.code.includes(LENDERS_FEES))
    );
    const lendersEndorsements = getAmount(
      accounting?.find((acc) => acc.code.includes(SUM_LENDERS_ENDORSEMENTS))
    );

    const ownersDisclosed = getAmount(
      accounting?.find((acc) => acc.code.includes(OWNERS_DISCLOSED))
    );
    const ownersActual = getAmount(
      accounting?.find((acc) => acc.code.includes(OWNERS_ACTUAL))
    );
    const ownersFees = getAmount(
      accounting?.find((acc) => acc.code.includes(OWNERS_FEES))
    );
    const ownersEndorsements = getAmount(
      accounting?.find((acc) => acc.code.includes(SUM_OWNERS_ENDORSEMENTS))
    );

    const refinanceCredits = getAmount(
      accounting?.find((acc) => acc.code.includes(RefinanceCredits))
    );

    const lendersTotal = getAmount(
      accounting?.find((acc) => acc.code.includes(LENDERS_TOTAL))
    );

    const lendersPolicy = getAmount(
      accounting?.find((acc) => acc.code.includes(LendersPolicy))
    );

    const mapBalances = new Map();
    const balancesDisclosed = {
      owners_policy: policyData(
        t,
        ownersDisclosed,
        ownersEndorsements,
        ownersFees,
        tab
      ),
      lenders_policy: policyData(
        t,
        lendersDisclosed,
        lendersEndorsements,
        lendersFees,
        tab
      ),
    };

    const balancesActual = {
      owners_policy: policyData(
        t,
        ownersActual,
        ownersEndorsements,
        ownersFees,
        tab
      ),
      lenders_policy: policyData(
        t,
        lendersActual,
        lendersEndorsements,
        lendersFees,
        tab
      ),
    };

    const balancesRefinance = {
      lenders_policy: policyDataRefinance({
        t,
        policy: lendersPolicy,
        credits: refinanceCredits,
        total: lendersTotal
      }),
    };

    mapBalances.set('disclosed', balancesDisclosed);
    mapBalances.set('actual', balancesActual);
    mapBalances.set('refinance', balancesRefinance);
    return mapBalances;
  }, [accounting, t, tab]);

  return (
    <Grid container direction="column" className={classes.container}>
      {typeTransaction !== REFINANCE ? (
        <>
          <Grid item xs={12} className={classes.buttonsContainer}>
            <ButtonGroup
              fullWidth
              disableElevation
              color="primary"
              size="small"
            >
              <Button
                className={classes.button}
                variant={tab === 'disclosed' ? 'contained' : 'outlined'}
                onClick={() => setTab('disclosed')}
              >
                {t('policyInfoAndRates:disclosed')}
              </Button>

              <Button
                className={classes.button}
                variant={tab === 'actual' ? 'contained' : 'outlined'}
                onClick={() => setTab('actual')}
              >
                {t('policyInfoAndRates:actual')}
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item xs={12}>
            <BalanceInfo
              title={t('policyInfoAndRates:owners-policy')}
              data={policyBalance.get(tab).owners_policy}
            />
          </Grid>
          <Grid item xs={12} className={classes.spacerContainer}>
            <BalanceInfo
              title={t('policyInfoAndRates:lenders-policy')}
              data={policyBalance.get(tab).lenders_policy}
            />
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          <BalanceInfo
            title={t('policyInfoAndRates:lenders-policy')}
            data={policyBalance.get('refinance').lenders_policy}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default Balance;
