import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ContainedButton } from 'components/ContainedButton';
import NumberField from 'components/number-field';
import React, { FC, useMemo } from 'react';
import { Controller, UseFormReturn, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AccountingInvolvedParty, AccountingType } from 'v2-types/order';

import SelectFieldLink from './components/link-entry-select';
import { createlinkForm } from './services';
import { formatMoney, IntlCurrencyFormat } from 'utils/helpers';

const useStyles = makeStyles((theme) => ({
  text: {
    textTransform: 'none',
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  errorIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

type ModalLinkSceneProps = {
  onClose: () => void;
  ledgerEntries: AccountingType[];
  open: boolean,
  methods: UseFormReturn<createlinkForm, object>
  handleSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  errors: string[];
  loadingCreation: boolean
}

const CustomElementLinkSelect: FC<{ element: AccountingInvolvedParty }> = ({ element }) => {
  return (
    <Typography variant="body2">
      {`${element.order_kinds ? `(${element.order_kinds})` : ''} ${element.name}, Resting: ${element.amount - element.accounted_amount}(total amount: ${element.amount})`}
    </Typography>
  );
};

const CustomElementEntrySelect: FC<{ element: AccountingType }> = ({ element }) => {
  return (
    <Typography variant="body2">
      {`
       ${element?.letter !== null || element.number !== null ? `(${element?.letter !== null ? `${element?.letter}` : ''}
           ${element?.number !== null ? `${element?.number}` : ''})` : ''}
      ${element.description} -
    ${element.amount ? IntlCurrencyFormat(element.amount || 0) : ''} 
    `}
    </Typography>
  );
};

const ModalLinkScene: FC<ModalLinkSceneProps> = ({
  open,
  onClose,
  ledgerEntries,
  methods,
  handleSubmit,
  errors,
  loadingCreation,
}) => {
  const { setValue, control, formState } = methods;
  const selectedEntry = useWatch({
    control,
    name: 'targetEntryId',
  });
  const selectedEntryLedger = ledgerEntries.find((f) => f._id === selectedEntry);
  const { t } = useTranslation();
  const classes = useStyles();
  const filteredEntries = [
    ...ledgerEntries.filter(entry => entry.amount > 0 && entry.letter !== null).sort((a, b) => a.letter?.localeCompare(b?.letter)),
    ...ledgerEntries.filter(entry => entry.amount > 0 && entry.letter === null).sort((a, b) => a.description?.localeCompare(b?.description))
  ];

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      fullWidth
    >
      <form onSubmit={handleSubmit}>
        <DialogContent style={{ padding: 16 }}>
          <Box>
            <Typography variant="h6" style={{ color: 'black' }}>
              Create Link to entry
            </Typography>
          </Box>
          <Box display="flex" flex="1">
            {!!errors.length && (
              <Alert severity="error" classes={{ icon: classes.errorIconContainer }}>
                {errors.map((error) => (
                  <Typography variant="subtitle1">{error}</Typography>
                ))}
              </Alert>
            )}
          </Box>
          <Controller
            name="targetEntryId"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <SelectFieldLink<AccountingType>
                {...field}
                inputRef={ref}
                value={field.value || ''}
                data={filteredEntries}
                label="Select entry"
                handleChange={(e) => {
                  field.onChange(e);
                  setValue('targetAssociationId', null);
                  setValue('targetAssociationKind', null);
                }}
                CustomElement={CustomElementEntrySelect}
              />
            )}
          />
          <Controller
            name="targetAssociationId"
            control={control}
            rules={{ required: true }}
            render={({ field: { ref, ...field } }) => (
              <SelectFieldLink<AccountingInvolvedParty>
                {...field}
                value={field.value || ''}
                inputRef={ref}
                disabled={!selectedEntry}
                data={selectedEntryLedger?.involved_parties.filter(entry => entry.amount > 0).sort((a, b) => a.order_kinds.join(',').localeCompare(b.order_kinds.join(''))) || []}
                label="Select association"
                CustomElement={CustomElementLinkSelect}
                handleChange={(e) => {
                  field.onChange(e);
                  const selectedParty = selectedEntryLedger?.involved_parties.find(
                    (g) => g._id === e.target.value
                  );
                  if (selectedParty) {
                    setValue('targetAssociationKind', selectedParty?.kind);
                  }
                }}
                error={!!formState.errors.targetAssociationId}
              />
            )}
          />
          <Controller
            name="amount"
            control={control}
            rules={{ required: true }}
            render={({ field: { ref, ...field } }) => (
              <NumberField
                {...field}
                value={field.value || 0}
                customRef={ref}
                thousandSeparator=","
                prefix="$"
                onChange={undefined}
                onValueChange={(e) => {
                  field.onChange(e.floatValue);
                }}
                error={!!formState.errors.amount}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" disableElevation onClick={onClose}>
            <Typography variant="body2" className={classes.text}>
              {t('common:close')}
            </Typography>
          </Button>

          <ContainedButton
            text="Create a Link"
            type="submit"
            isLoading={loadingCreation}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ModalLinkScene;
